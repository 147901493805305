import { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { RedirectAs404 } from "../utils/Utils";
import LeanPaths from "../lean-util/LeanPaths";
import HomeView from "./panels/Home/HomeView";
import StoreFormView from "./store/form/StoreFormView";
import StoreListView from "./store/list/StoreListView";
import AdminFormView from "./admin/form/AdminFormView";
import AdminListView from "./admin/list/AdminListView";
import OrderSyncListView from "./order-sync/list/OrderSyncListView";
import OrderSyncFormView from "./order-sync/form/OrderSyncFormView";
import StoreReportView from "./store-report/list/StoreReportView";


const Pages = () =>
{
    useLayoutEffect(() =>
    {
        window.scrollTo(0, 0);
    });

    const getPath = (path) =>
    {
        return `${process.env.PUBLIC_URL}${path}`;
    }

    return (
        <Suspense fallback={<div />}>
            <Switch>
                <Route exact path={getPath(LeanPaths.home)} component={HomeView}></Route>

                <Route exact path={getPath(LeanPaths.adminAddPath)} component={AdminFormView} />
                <Route exact path={getPath(LeanPaths.adminEditPath)} component={AdminFormView} />
                <Route exact path={getPath(LeanPaths.adminListPath)} component={AdminListView} />

                <Route exact path={getPath(LeanPaths.syncAddPath)} component={OrderSyncFormView} />
                <Route exact path={getPath(LeanPaths.syncListPath)} component={OrderSyncListView} />

                <Route exact path={getPath(LeanPaths.storeAddPath)} component={StoreFormView} />
                <Route exact path={getPath(LeanPaths.storeEditPath)} component={StoreFormView} />
                <Route exact path={getPath(LeanPaths.storeListPath)} component={StoreListView} />

                <Route exact path={getPath(LeanPaths.storeReportPath)} component={StoreReportView} />

                <Route component={RedirectAs404} />
            </Switch>
        </Suspense>
    );
};

export default Pages;
