import { Card } from "reactstrap";
import LeanContentLoader from "./LeanContentLoader";

const LeanCard = ({ bodyClassName, children, className, isLoading = false, loadingType = "form", ...props }) =>
{
    const getContent = () =>
    {
        if (isLoading)
            return <LeanContentLoader type={loadingType} />

        return children
    }

    return (
        <Card className={"card-bordered " + className} {...props}>
            <div className={"card-inner " + bodyClassName}>
                {getContent()}
            </div>
        </Card>
    );
};

export default LeanCard;
