import React, { Suspense } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import ReactDOM from "react-dom"
import reportWebVitals from "./reportWebVitals"
import App from "./App"

ReactDOM.render(
    <React.Fragment>
        <Suspense fallback={<div />}>
            <Router basename={`/`}>
                <Switch>
                    <Route path={process.env.PUBLIC_URL} component={App}></Route>
                </Switch>
            </Router>
        </Suspense>
    </React.Fragment>,

    document.getElementById("root")

)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals()
