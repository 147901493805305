import { useContext } from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { languages } from "utils/Utils";
import { messages } from "locales/messages";
import { LanguageContext } from "contexts/LanguageContext";
import { UITranslator } from "components"

const LanguageDropdownView = () =>
{
    const { selectedLanguage, handleLanguageChange } = useContext(LanguageContext);

    return (
        <UncontrolledDropdown className="language-dropdown">
            <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
                <div className="lean-languageBox">
                    <img
                        className="lean-flagIcon active"
                        src={`/assets/${selectedLanguage?.img}`}
                        alt={selectedLanguage?.urlLang === "pt" ? "Ícone do Brasil" : "Icon from United States of America"}
                    />
                </div>
            </DropdownToggle>

            <DropdownMenu right className="dropdown-menu dropdown-menu-s1">
                <div className="dropdown-body">
                    <ul className="lean-languageList">
                        {Object.entries(languages).map(
                            ([languageKey, languageValue]) =>
                                languageValue.code !== selectedLanguage?.code && (
                                    <li
                                        key={languageKey}
                                        className="lean-languageItem language-item dropdown-item"
                                        onClick={() => handleLanguageChange(languageValue.urlLang)}
                                    >
                                        <img
                                            className="lean-flagIcon language-flag mr-2"
                                            src={`/assets/${languageValue.img}`}
                                            alt={messages[languageValue.imgAlt]}
                                        />
                                        <span className="language-name">
                                            <UITranslator id={languageValue.language} />
                                        </span>
                                    </li>
                                )
                        )}
                    </ul>
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default LanguageDropdownView;
