import { useOrderAPI } from "./useOrderAPI"

export function useOrderService()
{
    // MARK: - Properties

    const orderAPI = useOrderAPI()


    // MARK: - Methods

    const fetchOrderSyncList = async (parameters) =>
    {
        return await orderAPI.fetchOrderSyncList(parameters)
    }

    const insertOrderSync = async (parameters) =>
    {
        return await orderAPI.insertOrderSync(parameters)
    }

    return {
        fetchOrderSyncList,
        insertOrderSync
    }
}
