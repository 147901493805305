import React, { useContext } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
import LeanPaths from "lean-util/LeanPaths";

const PrivateRoute = ({ exact, component: Component, ...rest }) =>
{
    // MARK: - Properties

    const { isAuthenticated } = useContext(AuthContext);


    // MARK: - Render

    if (!isAuthenticated)
        return <Route rest exact render={() => <Redirect to={process.env.PUBLIC_URL + LeanPaths.auth.login}></Redirect>}></Route>

    return (
        <Switch>
            <Route exact rest render={(props) => <Component {...props} {...rest}></Component>}>
            </Route>
        </Switch>
    );
}

export default PrivateRoute
