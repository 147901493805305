import React, { useState } from "react";
import { UICol, UIIcon } from "../../components";
import { FormGroup } from "reactstrap";
import { isNotNil } from "lean-util/LeanHelpers";

const LeanInput = ({
    id,
    type,
    note,
    label,
    error,
    inline,
    leftIcon,
    register,
    className,
    onlyNumbers,
    leftInputGroup,
    labelAccessory,
    ...props
}) =>
{
    // MARK: - Properties

    const [fieldType, setFieldType] = useState(type);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const isPassword = type === "password";
    const passwordClassName = isPassword ? isPasswordVisible ? "is-shown" : "is-hidden" : "";


    // MARK: - Methods

    const getDefaultComponent = () =>
    {
        return (
            <>
                <div className="form-label-group">
                    <label className="form-label" htmlFor={id}>
                        {label}
                    </label>
                    {labelAccessory}
                </div>
                {getInput()}
            </>
        )
    }

    const getInlineComponent = () =>
    {
        return (
            <>
                <UICol lg="6">
                    <FormGroup>
                        <label className={isNotNil(note) ? "form-label" : "form-label mb-0"} htmlFor={id}>{label}</label>
                        {note && (<span className="form-note">{note}</span>)}
                    </FormGroup>
                </UICol>
                <UICol lg="6">
                    <FormGroup>
                        {getInput()}
                    </FormGroup>
                </UICol>
            </>
        )
    }

    const getInput = () =>
    {
        return (
            <div className="form-control-wrap">
                {!!leftInputGroup ? (
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">{leftInputGroup}</span>
                        </div>
                        <input type={fieldType} {...(register && register(id))} className={`form-control ${className} ${passwordClassName}`} onKeyPress={onKeyPress} {...props} />
                    </div>
                ) : (
                    <>
                        {!!leftIcon && (
                            <div className="form-icon form-icon-left">{leftIcon}</div>
                        )}

                        {isPassword && (
                            <button
                                type="button"
                                className={`form-icon bg-transparent lg form-icon-right passcode-switch ${passwordClassName}`}
                                style={{ border: 0, outline: 0 }}
                                onClick={updatePasswordState}
                            >
                                <UIIcon name="eye" className="passcode-icon icon-hide"></UIIcon>
                                <UIIcon name="eye-off" className="passcode-icon icon-show"></UIIcon>
                            </button>
                        )}

                        {fieldType === "textarea" ? (
                            <textarea
                                {...(register && register(id))}
                                className={`form-control ${className} ${passwordClassName}`}
                                onKeyPress={onKeyPress}
                                {...props}
                            />
                        ) : (
                            <input
                                type={fieldType}
                                {...(register && register(id))}
                                className={`form-control ${className} ${passwordClassName}`}
                                onKeyPress={onKeyPress}
                                {...props}
                            />
                        )}
                    </>
                )}

                {note && !inline && <span className="form-note">{note}</span>}
                {error && <span className="invalid">{error.message}</span>}

            </div>
        )
    }

    const onKeyPress = (event) =>
    {
        if (onlyNumbers)
        {
            if (!/[0-9]/.test(event.key)) event.preventDefault();
        }

        if (props.onKeyPress)
            props.onKeyPress(event);
    }

    const updatePasswordState = (event) =>
    {
        event.preventDefault();
        setFieldType(isPasswordVisible ? "password" : "text");
        setIsPasswordVisible(!isPasswordVisible);
    }

    // MARK: - Render

    return (
        <>
            {inline ? getInlineComponent() : getDefaultComponent()}
        </>
    );
};

export default LeanInput;
