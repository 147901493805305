import React from "react"
import classNames from "classnames"
import { Spinner } from "reactstrap"

const UIButton = React.forwardRef(({ className, color, dim, size, outline, disabled = false, isLoading = false, ...props }, ref) => 
{
    const buttonClass = classNames({
        btn: true,
        disabled: disabled,
        [`${className}`]: className,
        [`btn-${color}`]: !outline,
        [`btn-outline-${color}`]: outline,
        [`btn-${size}`]: size,
        "btn-dim": dim,
        "text-uppercase": true,
        "justify-content-center": true
    })

    return (
        <button className={buttonClass} disabled={disabled || isLoading} {...props} ref={ref}>
            {isLoading ? <Spinner size="sm" color="light" /> : props.children}
        </button>
    )
})

export default UIButton
