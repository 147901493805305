import APIErrorHandler from "utils/APIErrorHandler"
import HttpClient from "modules/networking/http-client/HttpClient"
import HttpHeaderManager from "modules/networking/managers/HttpHeaderManager"

export function useOrderAPI()
{
    // MARK: - Properties

    const headerManager = HttpHeaderManager()


    // MARK: - Methods

    const fetchOrderSyncList = async (parameters) =>
    {
        try { return await HttpClient.get("/v1/order/sync/list", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const insertOrderSync = async (parameters) =>
    {
        try { return await HttpClient.post("/v1/order/sync", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    return {
        fetchOrderSyncList,
        insertOrderSync,
    }
}
