import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';

Yup.setLocale(ptForm);

const useFormWithValidation = () => 
{
    const schema = Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().required()
    })

   return useForm({ resolver: yupResolver(schema) })
}

export
{ 
    useFormWithValidation
}
