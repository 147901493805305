import { LeanAlert } from "lean-components/LeanComponents"
import { useTranslator } from "lean-util/LeanTranslator"
import { UIDataTableCellActions } from "components"

const useColumns = (onEdit, onDelete) =>
{
    // MARK: - Properties

    const { translate } = useTranslator()


    // MARK: - Methods

    const showDeleteAlert = (id) =>
    {
        LeanAlert.delete(false, () => { onDelete(id) })
    }

    return [
        {
            accessorKey: "name",
            header: () => translate("name"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "url",
            header: () => translate("url"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "id",
            header: () => translate("actions"),
            cell: info => <UIDataTableCellActions id={info.row.original.id} onDelete={showDeleteAlert} onEdit={onEdit} />,
            size: 100
        },
    ]
}

export { useColumns }
