import APIErrorHandler from "utils/APIErrorHandler"
import HttpClient from "modules/networking/http-client/HttpClient"
import HttpHeaderManager from "modules/networking/managers/HttpHeaderManager"

export function useStoreAPI()
{
    // MARK: - Properties

    const headerManager = HttpHeaderManager()


    // MARK: - Methods

    const deleteStore = async (parameters) =>
    {
        try { return await HttpClient.delete("/v1/store", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const fetchStore = async (parameters) =>
    {
        try { return await HttpClient.get("/v1/store", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const fetchStoreList = async (parameters) =>
    {
        try { return await HttpClient.get("/v1/store/list", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const fetchStoreReport = async (parameters) =>
    {
        try { return await HttpClient.get("/v1/store/report", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const insertStore = async (parameters) =>
    {
        try { return await HttpClient.post("/v1/store", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const updateStore = async (parameters) =>
    {
        try { return await HttpClient.put("/v1/store", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    return {
        deleteStore,
        fetchStore,
        insertStore,
        updateStore,
        fetchStoreList,
        fetchStoreReport
    }
}
