import UIDataTableCellOrderSyncStatus from "components/custom/data-table-cell/order-sync-status/UIDataTableCellOrderSyncStatus"
import { useTranslator } from "lean-util/LeanTranslator"

const useColumns = () =>
{
    // MARK: - Properties

    const { translate } = useTranslator()


    return [
        {
            accessorKey: "store",
            header: () => translate("store"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "syncFrom",
            header: () => translate("period"),
            cell: info => { return info.getValue() + " até " + info.row.original.syncTo }
        },
        {
            accessorKey: "startedAt",
            header: () => translate("start"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "endedAt",
            header: () => translate("end"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "insertedOrders",
            header: () => translate("inserts"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "updatedOrders",
            header: () => translate("updates"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "status",
            header: () => translate("status"),
            cell: info => <UIDataTableCellOrderSyncStatus info={info} />
        }
    ]
}

export { useColumns }
