import React from "react"

const UIAuthContainer = ({ children, ...props }) =>
{
    return (
        <div className="nk-app-root">
            <div className="nk-wrap nk-wrap-nosidebar">
                <div className="nk-content">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default UIAuthContainer
