import classNames from "classnames"

const UIRow = ({ className, inline, children, ...props }) =>
{
    const rowClassName = classNames({
        row: true,
        [`${className}`]: className,
        // "g-3 align-center": inline,
        // "g-5": !inline
    })

    return (
        <div className={rowClassName} {...props}>
            {children}
        </div>
    )
}

export default UIRow
