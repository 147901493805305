import { UIIcon, UITranslator, UIRow, UICol } from "components";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

const UIDataTablePagination = ({ rowCount, table }) =>
{
    const page = table.getState().pagination.pageIndex + 1
    const pageSize = table.getState().pagination.pageSize
    const pageCount = isNaN(table.getPageCount()) ? 0 : table.getPageCount()
    const pageNumbers = [...Array(pageCount)].map((_x, i) => i + 1)

    const getBeginOfPage = () =>
    {
        return 1 + pageSize * (page - 1);
    }

    const getEndOfPage = () =>
    {
        let value = pageSize * page;

        if (value > rowCount)
            return rowCount;

        return value;
    }

    const goToPage = (event, index) =>
    {
        event.preventDefault()

        if (index !== page)
            table.setPageIndex(index - 1)
    }

    return (
        <UIRow className={`align-items-center mt-4 ${pageCount === 1 ? "d-none" : ""}`}>
            <UICol className="col-7" sm="12" md="9">
                <Pagination aria-label="Navegação">

                    <PaginationItem disabled={!table.getCanPreviousPage()}>
                        <PaginationLink href="#prev" className="page-link-prev" onClick={(e) => { e.preventDefault(); table.firstPage() }}>
                            <UIIcon name="chevrons-left" />
                            <span>
                                <UITranslator id="btnFirst" />
                            </span>
                        </PaginationLink>
                    </PaginationItem>

                    <PaginationItem disabled={!table.getCanPreviousPage()}>
                        <PaginationLink href="#prev" className="page-link-prev" onClick={(e) => { e.preventDefault(); table.previousPage() }}>
                            <UIIcon name="chevron-left" />
                            <span>
                                <UITranslator id="btnPrevious" />
                            </span>
                        </PaginationLink>
                    </PaginationItem>

                    {pageNumbers.map((item) => item <= page + 2 && item >= page - 2 && (
                        <PaginationItem className={`d-none d-sm-block ${page === item ? "active" : ""}`} key={item}>
                            <PaginationLink tag="a" href="#pageitem" onClick={(e) => { goToPage(e, item) }}>
                                {item}
                            </PaginationLink>
                        </PaginationItem>
                    ))}

                    <PaginationItem disabled={!table.getCanNextPage()}>
                        <PaginationLink href="#next" className="page-link-next" onClick={(e) => { e.preventDefault(); table.nextPage() }}>
                            <span>
                                <UITranslator id="btnNext" />
                            </span>
                            <UIIcon name="chevron-right" />
                        </PaginationLink>
                    </PaginationItem>
                    <PaginationItem disabled={!table.getCanNextPage()}>
                        <PaginationLink href="#next" className="page-link-next" onClick={(e) => { e.preventDefault(); table.lastPage() }}>
                            <span>
                                <UITranslator id="btnLast" />
                            </span>
                            <UIIcon name="chevrons-right" />
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            </UICol>

            <UICol sm="12" md="3" className="col-5 text-left text-md-right">
                <div className="dataTables_info" id="DataTables_Table_2_info" role="status" aria-live="polite">
                    {getBeginOfPage()} - {getEndOfPage()} <UITranslator id="of" /> {rowCount}
                </div>
            </UICol>
        </UIRow>
    );
}

export default UIDataTablePagination
