import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { ptForm } from "yup-locale-pt"
import { yupResolver } from "@hookform/resolvers/yup"

Yup.setLocale(ptForm)

const getDefaultValues = () =>
{
    return {}
}

const useFormWithValidation = (store) =>
{
    const schema = Yup.object().shape({
        from: Yup.string().required(),
        storeId: Yup.string().required(),
        to: Yup.string().required(),
    })


    return useForm({
        defaultValues: getDefaultValues(),
        resolver: yupResolver(schema)
    })
}

export { useFormWithValidation }
