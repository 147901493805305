import Head from "layout/head/Head"
import { Form } from "reactstrap"
import { UIButton, UITranslator, UIContent, UIPageTitle, UICard, UIRow, UICol } from "components"
import { LeanInput, LeanSwitch } from "lean-components/LeanComponents"
import { useViewModel } from "./AdminFormViewModel"
import { useEffect } from "react"

const AdminFormView = () =>
{
    const viewModel = useViewModel()
    const form = viewModel.form
    const formErrors = form.formState.errors

    const getPageTitleRightContent = () =>
    {
        return (
            <>
                <UIButton outline type="button" color="primary" className="mr-2" onClick={viewModel.goBack}>
                    <UITranslator id="btnBack" />
                </UIButton>
                <UIButton form="form" type="submit" color="primary" isLoading={viewModel.isSaving}>
                    <UITranslator id="btnSave" />
                </UIButton>
            </>
        )
    }

    useEffect(() =>
    {
        if (viewModel.admin)
            form.reset(viewModel.admin)

    }, [viewModel.admin])

    return (
        <>
            <Head title="user" />

            <UIContent type="form">

                <UIPageTitle title="adminPageTitle" description="adminPageDescription" rightContent={getPageTitleRightContent()} />

                <UICard isLoading={viewModel.isLoading} loadingType="form">
                    <Form id="form" onSubmit={form.handleSubmit(viewModel.saveAdmin)}>
                        <UIRow>
                            <UICol lg="3">
                                <LeanSwitch id="isActive" label={<UITranslator id="isActive" />} register={form.register} />
                            </UICol>
                        </UIRow>
                        <UIRow>
                            <UICol lg="12">
                                <LeanInput id="name" label="Nome*" register={form.register} error={formErrors.name} />
                            </UICol>
                        </UIRow>
                        <UIRow>
                            <UICol lg="12">
                                <LeanInput id="email" label="E-mai*" register={form.register} error={formErrors.email} />
                            </UICol>
                        </UIRow>

                        {viewModel.admin === null && (
                            <UIRow>
                                <UICol lg="12">
                                    <LeanInput type="password" id="password" label="Senha*" register={form.register} error={formErrors.email} />
                                </UICol>
                            </UIRow>
                        )}

                    </Form>
                </UICard>

            </UIContent>
        </>
    )
}

export default AdminFormView
