const UIDataTableCellOrderSyncStatus = ({ info }) => 
{
    const { error, hasError, id, status, } = info.row.original

    const getCompletedContent = () =>
    {
        if (hasError)
            return <span className="badge badge-sm badge rounded-pill bg-danger">Concluído com erro</span>


        return <span className="badge badge-sm rounded-pill bg-success">Concluído</span>
    }

    switch (status)
    {
        case "queued":
            return <span className="badge badge-sm rounded-pill bg-info">Aguardando</span>

        case "started":
            return <span className="badge badge-sm rounded-pill bg-primary">Em andamento</span>

        case "completed":
            return getCompletedContent()

        default:
            return ""

    }
}

export default UIDataTableCellOrderSyncStatus
