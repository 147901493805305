import { Helmet } from "react-helmet";
import { useTranslator } from "lean-util/LeanTranslator";

const Head = ({ title = "", ...props }) =>
{
    const { translate } = useTranslator();

    return (
        <Helmet {...props}>
            <title>{`${title ? `${translate(title)} | ` : ""}Admin`}</title>
        </Helmet>
    );
};
export default Head;
