import { useTranslator } from "lean-util/LeanTranslator";
import Swal from "sweetalert2";

// MARK: - Methods

const defaultAlert = (title, text, onConfirm) => 
{
    Swal.fire({
        title: title,
        text: text,
        confirmButtonText: "Fechar",
    }).then(() =>
    {
        if (onConfirm)
            onConfirm()
    });
}

const deleteAlert = (isReversible, onConfirm) =>
{
    const text = isReversible
        ? "O registro será removido, porém, é necessário salvar para confirmar a exclusão."
        : "Não será possível reverter essa ação."
    
    Swal.fire({
        title: "Quer realmente excluir este registro?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Voltar",
        confirmButtonText: "Sim, excluir!",
        reverseButtons: true
    }).then((result) =>
    {
        if (result.isConfirmed && onConfirm)
            onConfirm()
    })
}

const errorAlert = (title, onConfirm) =>
{
    Swal.fire({
        title: title,
        icon: "error",
        confirmButtonText: "Fechar",
    }).then((result) =>
    {
        if (onConfirm)
            onConfirm()
    })
}

// const successAlert = (title, onConfirm) =>
// {
//     Swal.fire({
//         title: title,
//         icon: "success",
//         confirmButtonText: "Fechar",
//     }).then((result) =>
//     {
//         if (onConfirm)
//             onConfirm()
//     })
// }

const successAlert = ({ title, text, onConfirm }) =>
{   
    Swal.fire({
        title: title,
        text: text,
        icon: "success",
        confirmButtonText: "Fechar",
    }).then((result) =>
    {
        if (onConfirm)
            onConfirm()
    })
}


// MARK: - Init

const LeanAlert = {
    default: defaultAlert,
    delete: deleteAlert,
    error: errorAlert,
    success: successAlert
}

export default LeanAlert
