import classNames from "classnames";
import { isNotNil } from "lean-util/LeanHelpers";
import React from "react";

const UIIcon = ({ name, id, className, size, style, fa, fab, ...props }) =>
{
    const iconClass = classNames({
        [`${className}`]: className,
        [`lean-icon-size-${size}`]: isNotNil(size),
        [`fab`]: fab,
        [`far`]: fa,
        [`fa-${name}`]: fa || fab,
        [`ni-${name}`]: !fa,
        [`ni`]: !fa && !fab,
        [`icon`]: true
    })

    return <em className={iconClass} id={id} style={style} {...props}></em>
}

export default UIIcon
