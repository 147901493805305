import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const LeanContentLoader = ({ className, type, ...props }) =>
{
    if (type === "component")
    {
        return <Skeleton height={38} />;
    }

    if (type === "data-table")
    {
        return (
            <table className={className} width="100%">
                <tbody>
                    {[...Array(props.count ?? 5)].map((x, ix) => (
                        <tr key={ix}>
                            {[...Array(props.cols ?? 3)].map((y, iy) => (
                                <td key={ix + "-" + iy}>{<Skeleton />}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    if (type === "form")
    {
        return (
            <>
                <Skeleton count={2} width={100} />
                <br />
                <Skeleton count={4} />
            </>
        );
    }

    if (type === "integration")
    {
        return <Skeleton count={2} />
    }

    if (type === "product")
    {
        return (
            <>
                <Skeleton className="mb-2" height={100} />
                <Skeleton count={3} />
            </>
        );
    }

    return <Skeleton {...props} />;
};

export default LeanContentLoader;
