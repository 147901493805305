import { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";

export default function HttpHeaderManager()
{
    // MARK: - Properties

    const { token } = useContext(AuthContext);

    // MARK: - Methods

    const getAuthHeaders = () =>
    {
        return {
            Authorization: `Bearer ${token}`,
        };
    };

    return {
        authHeaders: getAuthHeaders(),
    };
}
