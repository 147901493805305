import { languages } from "utils/Utils";

export const messages =
{
    [languages.pt.code]:
    {
        // 
        // Pages
        //

        adminPageDescription: "Gerencie os administradores da aplicação",
        adminPageTitle: "Administradores",

        orderSyncPageDescription: "Sincronize os pedidos das lojas e consulte as informações de vendas na seção de relatórios",
        orderSyncPageTitle: "Sincronização de pedidos",

        storePageDescription: "Gerencie as lojas da aplicação",
        storePageTitle: "Lojas",

        reportPageDescription: "Consulte as informações de vendas das lojas",
        reportPageTitle: "Relatórios",

        //
        // Months
        //

        january: "Janeiro",
        february: "Fevereiro",
        march: "Março",
        april: "Abril",
        may: "Maio",
        june: "Junho",
        july: "Julho",
        august: "Agosto",
        september: "Setembro",
        october: "Outubro",
        november: "Novembro",
        december: "Dezembro",


        // 
        // Literal
        //

        addAll: "Adicionar todas",
        addAllFirstLevel: "Adicionar todas do 1º nível",
        access: "Acessar",
        active: "Ativo",
        android: "Android",
        attributeName: "Nome do atributo",
        attributeType: "Tipo do atributo",
        auth: "Autenticação",
        authenticatedPriceSettingLabel: "Exibir somente quando autenticado",
        authenticatedPriceSettingNote: "O preço será exibido após o cliente realizar o login",
        catalog: "Catálogo",
        categories: "Categorias",
        checkout: "Checkout",
        code: "Código",
        collections: "Coleções",
        configurations: "Configurações",
        datePicketAt: "ÀS",
        defaultSorting: "Ordenação padrão",
        description: "Descrição",
        enable: "Habilitar",
        example: "Exemplo:",
        file: "Arquivo",
        fixedHeightForGridLabel: "Exibir produtos com tamanho fixo",
        fixedHeightForGridNote: "Todos os cards de produto terão o mesmo tamanho",
        generalSettingsAuthEnableAccessKeyLabel: "Login com chave de acesso",
        generalSettingsAuthEnableAccessKeyNote: "Opção para os usuários acesarem suas contas sem utilizar senha",
        generalSettingsAuthMessage: "Selecione as opções de autenticação.",
        generalSettingsAuthTitle: "Autenticação",
        generalSettingsCatalogLastUnitsMessage: "Selecione as configurações do aviso de últimas unidades do produto.",
        generalSettingsCatalogLastUnitsTitle: "Aviso de últimas unidades",
        generalSettingsCatalogLayoutMessage: "Selecione as configurações de layout do catálogo.",
        generalSettingsCatalogLayoutTitle: "Layout",
        generalSettingsCatalogPricesMessage: "Selecione as configurações de preços do catálogo.",
        generalSettingsCatalogPricesTitle: "Preços",
        generalSettingsCatalogSearchAndSortMessage: "Selecione as configurações de busca e ordenação do catálogo.",
        generalSettingsCatalogSearchAndSortTitle: "Busca e ordenação",
        generalSettingsCategoryLayoutMessage: "Selecione as configurações de layout das categorias.",
        generalSettingsCheckoutDeliveryGroupSettingLabel: "Agrupar opções de envio",
        generalSettingsCheckoutDeliveryGroupSettingNote: "Serão exibidas as opções: \"Mais econômico\" e \"Mais rápido\"",
        generalSettingsCheckoutDeliveryMessage: "Selecione as configurações da etapa de entrega do checkout.",
        generalSettingsCheckoutDeliveryProductsPackageSettingLabel: "Exibir produtos do pacote",
        generalSettingsCheckoutDeliveryProductsPackageSettingNote: "Aplicável quando houver apenas um pacote na entrega",
        generalSettingsCheckoutDeliveryTitle: "Entrega",
        generalSettingsProductPersonalizationMessage: "Selecione as configurações de personalização de produto.",
        generalSettingsProductPersonalizationTitle: "Personalização",
        generalSettingsSuccessMessage: "Configurações atualizadas com sucesso!",
        generalSettingsWishlistIsActiveNote: "Irá exibir a seção de favoritos na aba principal",
        gridType: "Tipo da grade",
        icon: "Ícone",
        image: "Imagem",
        imageHeight: "Altura da imagem",
        imageWidth: "Largura da imagem",
        import: "Importar",
        ios: "iOS",
        inactive: "Inativo",
        isFeedProduct: "Produto do feed",
        lastUnitPluralMessageSettingLabel: "Mensagem de últimas unidades",
        lastUnitPluralMessageSettingNote: "Utilize \"%02d\" como variável no modelo da mensagem",
        lastUnitPluralMessageSettingPlaceholder: "Ex.: Restam apenas %02d unidades",
        lastUnitQuantitySettingLabel: "Quantidade mínima",
        lastUnitQuantitySettingNote: "Estoque mínimo para exibir o aviso",
        lastUnitSingularMessageSettingLabel: "Mensagem de última unidade",
        lastUnitSingularMessageSettingPlaceholder: "Ex.: Última unidade disponível",
        layout: "Layout",
        main: "Principal",
        media: "Mídia",
        medias: "Mídias",
        new: "Novo",
        platforms: "Plataformas",
        panel: "Painel",
        pricing: "Precificação",
        product: "Produto",
        products: "Produtos",
        productCollections: "Coleções de produto",
        productFreeShippingLabel: "Frete grátis",
        productInstallmentLabel: "Número de parcelas",
        productPriceMaxLabel: "Preço máximo",
        productPriceMinLabel: "Preço",
        productPriceOldLabel: "Preço antes do desconto",
        productSpecTableDescription: "Adicione os atributos da especificação que serão exibidos em forma de tabela",
        productSpecTableTitle: "Tabela de especificações",
        productSpecName: "Nome da especificação",
        productSpecNameNote: "O nome da especificação deve ser o mesmo utilizado na plataforma do site",
        productUrl: "URL do produto",
        pronoun: "Pronome",
        rating: "Avaliação",
        ratingCount: "Número de avaliações",
        ratingsAndSales: "Avaliações e vendas",
        refresh: "Atualizar",
        releaseDate: "Data de lançamento",
        salesCount: "Número de vendas",
        seller: "Vendedor",
        removeAll: "Remover todas",
        reports: "Relatórios",
        searchType: "Tipo da busca",
        sortingType: "Ordenação",
        specification: "Especificação",
        specificationNew: "Nova especificação",
        specificationUpdate: "Atualização de especificação",
        store: "Loja",
        style: "Estilo",
        syncs: "Sincronizações",
        type: "Tipo",
        url: "URL",
        update: "Atualizar",
        version: "Versão",
        versions: "Versões",
        versionsControl: "Controle de versões",
        wishlist: "Favoritos",

        appKey: "Chave da aplicação",
        appToken: "Token da aplicação",
        from: "De",
        to: "Até",
        period: "Período",
        start: "Início",
        end: "Fim",
        inserts: "Inserções",
        updates: "Atualizações",
        status: "Status",


        // SideMenu
        accessControl: "Controle de acesso",
        administrator: "Administrador",
        administrators: "Administradores",

        dashboard: "Dashboard",
        generalSettings: "Configurações gerais",
        home: "Início",
        institutionalPages: "Páginas institucionais",
        logs: "Logs",
        marketing: "Marketing",
        panels: "Painéis",
        productGrid: "Grade de produto",
        productGrids: "Grades de produto",
        productSpecification: "Especificação de produto",
        productSpecifications: "Especificações de produto",
        settings: "Configurações",
        showcases: "Vitrines",
        socialMedia: "Redes sociais",
        specifications: "Especificações",
        storeSettings: "Configurações da loja",
        teste: "teste",
        tester: "Tester",
        testers: "Testers",
        users: "Usuários",


        // Languages
        english: "Inglês",
        portuguese: "Português",
        "english-imgAlt": "Ícone da bandeira dos EUA",
        "portuguese-imgAlt": "Ícone da bandeira do Brasil",

        // Buttons
        btnAdd: "Adicionar",
        btnBack: "Voltar",
        btnClear: "Limpar",
        btnEdit: "Editar",
        btnSave: "Salvar",
        btnSearch: "Pesquisar",
        btnSort: "Ordenação",


        // DataTable
        of: "de",
        show: "Exibir",
        btnLast: "Último",
        btnNext: "Próximo",
        btnFirst: "Primeiro",
        btnPrevious: "Anterior",
        emptyMessage: "Nenhum registro até o momento.",

        // Columns
        isActive: "Ativo",
        isMain: "Principal",
        isMandatory: "Obrigatório",
        name: "Nome",
        method: "Método",
        link: "Link",
        title: "Título",
        message: "Mensagem",
        date: "Data",
        site: "Site",
        actions: "Ações",
        email: "E-mail",
        orderNumber: "Pedido N°.",
        customer: "Cliente",
        amount: "Valor",
        platform: "Plataforma",
        environment: "Ambiente",
        validity: "Vigência",

        // Banner Display
        bannerDisplayInEffect: "Em vigência",
        bannerDisplayInactive: "Inativa",
        bannerDisplayStart: "Início",
        bannerDisplayEnd: "Fim",
        bannerDisplayAt: "ÀS",
        bannerDisplayComponents: "Componentes",


        // Auth
        logInEmailLabelAccessory: "Precisa de ajuda?",
        logInPasswordLabelAccessory: "Esqueceu sua senha?",
        logInDescription: "Acesse utilizando seu e-mail e senha",
        logInEmailPlaceholder: "Informe seu e-mail",
        logInPasswordPlaceholder: "Informe sua senha",
        passwordResetTitle: "Redefinição de senha",
        passwordResetSubtitle: "Agora escolha uma nova senha para acessar sua conta.",
        passwordResetPasswordLabel: "Nova senha",
        passwordResetPasswordPlaceholder: "Informe sua nova senha",
        passwordResetConfirmPasswordLabel: "Confirme a nova senha",
        passwordResetConfirmPasswordPlaceholder: "Confirme sua nova senha",
        passwordResetSuccessTitle: "Atualização da senha realizada com sucesso!",
        passwordResetSuccessMessage: "Acesse o painel com seu e-mail e a senha que você acabou de atualizar.",
        storesListDescription: "Selecione a loja que deseja acessar",

        btnSend: "Enviar",
        password: "Senha",
        btnLogIn: "Entrar",
        recoverPassword: "Recuperação de senha",
        backToLoginPage: "Voltar para página de login",
        weWillSendYouAnEmailWithInstructions:
            "Caso tenha esquecido sua senha, enviaremos um e-mail com instruções para redefini-la.",

        // Home
        sales: "Vendas",
        orders: "Pedidos",
        daily: "Diário",
        weekly: "Semanal",
        monthly: "Mensal",
        averageTicketTotal: "Ticket total",
        averageTicketAndroid: "Ticket médio (Android)",
        averageTicketIOS: "Ticket médio (IOS)",
        topCategoriesChartTitle: "Melhores categorias",
        topProductCardTitle: "Melhores produtos",
        totalSalesChartTitle: "Total de vendas",
        totalOrdersChartTitle: "Total de pedidos",
        recentOrdersDataTableTitle: "Pedidos recentes",
        lastSevenDays: "Últimos 7 dias",
        lastFifteenDays: "Últimos 15 dias",
        lastThirtyDays: "Últimos 30 dias",
        lastThreeMonths: "Últimos 3 meses",
        lastSixMonths: "Últimos 6 meses",
        ordersFromTheLastThirtyDays: "Pedidos dos últimos 30 dias",

        // Showcases
        showcase: "Vitrine",

        // Categories
        category: "Categoria",
        categorySync: "Sincronizar com seu painel de origem",
        btnViewList: "Visualizar lista",
        btnViewTree: "Visualizar árvore",
        parentCategory: "Categoria pai",
        whichCategory: "Qual categoria?",
        whatIsTheSearchTerm: "Qual é o termo de busca?",
        whatIsTheTypeOfTheAction: "Qual é o tipo de ação?",
        whichProductCollection: "Qual coleção de produtos?",

        // Integrations
        integrations: "Integrações",
        integrationClientId: "ID do cliente",
        integrationSandbox: "Ambiente de teste",
        integrationSecret: "Segredo",

        // Users
        user: "Usuário",
        basics: "Básico",
        personalInformation: "Informações pessoais",
        securitySettings: "Configurações de segurança",
        passwordConfirm: "Confirmação de senha",
        passwordConfirmError: "Confirmação de senha precisa ser igual ao campo senha",

        // Settings

        // Logs
        payloadDiff: "Diferença de dados",

        // Others
        filter: "Filtro",
        yes: "Sim",
        no: "Não",
        exit: "Sair",
        viewProfile: "Ver perfil",
        stores: "Lojas",
        search: "Pesquisar",
        path: "Caminho",
        developedBy: "Desenvolvido por",
        accountSettings: "Configurações da conta",
        selectAnOption: "Selecione uma opção",


        // Product selector 
        productGridCategories: "Selecione as categorias que possuem esta grade",
        productSpecCategories: "Selecione as categorias que possuem esta especificação",

        // Validation
        validationTypeNumber: "Informe um valor númerico"
    },

    [languages.en.code]:
    {
        //
        // Pages
        //

        adminPageDescription: "Manage application admins",
        adminPageTitle: "Admins",

        orderSyncPageDescription: "Sync store orders and view sales information in the reports section",
        orderSyncPageTitle: "Order Sync",

        storePageDescription: "Manage application stores",
        storePageTitle: "Stores",

        reportPageDescription: "Consult store sales information",
        reportPageTitle: "Reports",

        //
        // Months
        //

        january: "January",
        february: "February",
        march: "March",
        april: "April",
        may: "May",
        june: "June",
        july: "July",
        august: "August",
        september: "September",
        october: "October",
        november: "November",
        december: "December",


        //
        // Literal
        //

        addAll: "Add all",
        addAllFirstLevel: "Add all from 1st level",
        access: "Access",
        active: "Active",
        android: "Android",
        attributeName: "Attribute name",
        attributeType: "Attribute type",
        auth: "Auth",
        authenticatedPriceSettingLabel: "Only display when authenticated",
        authenticatedPriceSettingNote: "Price will be displayed after customer login",
        catalog: "Catalog",
        categories: "Categories",
        checkout: "Checkout",
        code: "Code",
        collections: "Collections",
        configurations: "Configurations",
        datePicketAt: "AT",
        defaultSorting: "Default sorting",
        description: "Description",
        enable: "Enable",
        example: "Example:",
        file: "File",
        fixedHeightForGridLabel: "Show products with fixed size",
        fixedHeightForGridNote: "All product cards will have the same size",
        generalSettingsAuthEnableAccessKeyLabel: "Login with access key",
        generalSettingsAuthEnableAccessKeyNote: "Option for users to access their account without using a password",
        generalSettingsAuthMessage: "Select auth options settings.",
        generalSettingsAuthTitle: "Auth",
        generalSettingsCatalogLastUnitsMessage: "Select product last units warning settings.",
        generalSettingsCatalogLastUnitsTitle: "Last units warning",
        generalSettingsCatalogLayoutMessage: "Select catalog layout settings.",
        generalSettingsCatalogLayoutTitle: "Layout",
        generalSettingsCatalogPricesMessage: "Select catalog prices settings.",
        generalSettingsCatalogPricesTitle: "Prices",
        generalSettingsCatalogSearchAndSortMessage: "Select catalog search and sorting settings.",
        generalSettingsCatalogSearchAndSortTitle: "Search and sorting",
        generalSettingsCategoryLayoutMessage: "Select categories layout settings.",
        generalSettingsCheckoutDeliveryGroupSettingLabel: "Group shipping options",
        generalSettingsCheckoutDeliveryGroupSettingNote: "The options will be displayed: \"More economical\" and \"Faster\"",
        generalSettingsCheckoutDeliveryMessage: "Please select checkout delivery step settings.",
        generalSettingsCheckoutDeliveryProductsPackageSettingLabel: "Show package products",
        generalSettingsCheckoutDeliveryProductsPackageSettingNote: "Applicable when there was only one package in the delivery.",
        generalSettingsCheckoutDeliveryTitle: "Delivery",
        generalSettingsProductPersonalizationMessage: "Select product personalization settings.",
        generalSettingsProductPersonalizationTitle: "Personalization",
        generalSettingsSuccessMessage: "Settings successfully updated!",
        generalSettingsWishlistIsActiveNote: "Will display the wishlist section on the main tab",
        gridType: "Grid type",
        icon: "Icon",
        image: "Image",
        imageHeight: "Image Height",
        imageWidth: "Image Width",
        import: "Import",
        inactive: "Inactive",
        ios: "iOS",
        isFeedProduct: "Feed product",
        lastUnitPluralMessageSettingLabel: "Last units message",
        lastUnitPluralMessageSettingNote: "Use \"%02d\" as a variable in the message template",
        lastUnitPluralMessageSettingPlaceholder: "E.g.: Only %02d units left",
        lastUnitQuantitySettingLabel: "Minimum quantity",
        lastUnitQuantitySettingNote: "Minimum stock to display the warning",
        lastUnitSingularMessageSettingLabel: "Last unit message",
        lastUnitSingularMessageSettingPlaceholder: "E.g.: Last unit available",
        layout: "Layout",
        main: "Main",
        media: "Media",
        medias: "Media",
        new: "New",
        panel: "Panel",
        platforms: "Platforms",
        pricing: "Pricing",
        product: "Product",
        products: "Products",
        productCollections: "Product collections",
        productFreeShippingLabel: "Free shipping",
        productInstallmentLabel: "Number of installments",
        productPriceMaxLabel: "Max price",
        productPriceMinLabel: "Price",
        productPriceOldLabel: "Price before discount",
        productSpecTableDescription: "Add specification attributes that will be displayed in table form",
        productSpecTableTitle: "Specifications table",
        productSpecName: "Specification name",
        productSpecNameNote: "The specification name must be the same as used on the website platform",
        productUrl: "Product URL",
        pronoun: "Pronoun",
        rating: "Rating",
        ratingCount: "Rating count",
        ratingsAndSales: "Ratings and sales",
        refresh: "Refresh",
        releaseDate: "Release date",
        removeAll: "Remove all",
        reports: "Reports",
        salesCount: "Sales count",
        searchType: "Search type",
        seller: "Seller",
        sortingType: "Sorting type",
        specification: "Specification",
        specificationNew: "New specification",
        specificationUpdate: "Specification update",
        store: "Store",
        style: "Style",
        syncs: "Syncs",
        type: "Type",
        update: "Update",
        url: "URL",
        version: "Version",
        versions: "Versions",
        versionsControl: "Versions control",
        wishlist: "Wishlist",

        appKey: "Application key",
        appToken: "Application token",
        from: "From",
        to: "To",
        period: "Period",
        start: "Start",
        end: "End",
        inserts: "Inserts",
        updates: "Updated",
        status: "Status",


        // SideMenu
        accessControl: "Access control",
        administrator: "Admin",
        administrators: "Admins",


        dashboard: "Dashboard",
        generalSettings: "General settings",
        home: "Home",
        institutionalPages: "Institutional pages",
        logs: "Logs",
        marketing: "Marketing",
        panels: "Panels",
        productGrid: "Product grid",
        productGrids: "Product grids",
        productSpecification: "Product specification",
        productSpecifications: "Product specifications",
        settings: "Settings",
        showcases: "Showcases",
        socialMedia: "Social media",
        specifications: "Specifications",
        storeSettings: "Store settings",
        tester: "Tester",
        testers: "Testers",
        users: "Users",


        // Languages
        english: "English",
        portuguese: "Portuguese",
        "english-imgAlt": "EUA flag icon",
        "portuguese-imgAlt": "Brazil flag icon",


        // Buttons
        btnAdd: "Add",
        btnBack: "Back",
        btnClear: "Clear",
        btnEdit: "Edit",
        btnSave: "Save",
        btnSearch: "Search",
        btnSort: "Sorting",


        // DataTable
        of: "of",
        show: "Show",
        btnNext: "Next",
        btnLast: "Last",
        btnFirst: "First",
        btnPrevious: "Previous",
        emptyMessage: "No records so far.",

        // Columns
        isActive: "Active",
        isMain: "Main",
        isMandatory: "Mandatory",
        name: "Name",
        method: "Method",
        email: "E-mail",
        link: "Link",
        title: "Title",
        site: "Website",
        message: "Message",
        date: "Date",
        orderNumber: "Order No.",
        customer: "Customer",
        amount: "Amount",
        actions: "Actions",
        platform: "Platform",
        environment: "Environment",
        validity: "Validity",

        // Banner Display
        bannerDisplayInEffect: "In effect",
        bannerDisplayInactive: "Inactive",
        bannerDisplayStart: "Start",
        bannerDisplayEnd: "End",
        bannerDisplayAt: "AT",
        bannerDisplayComponents: "Components",


        // Auth
        logInEmailLabelAccessory: "Need help?",
        logInPasswordLabelAccessory: "Forgot password?",
        logInDescription: "Access using your e-mail and password",
        logInEmailPlaceholder: "Inform your e-mail",
        logInPasswordPlaceholder: "Inform your password",
        passwordResetTitle: "Password reset",
        passwordResetSubtitle: "Now choose a new password to access your account.",
        passwordResetPasswordLabel: "New password",
        passwordResetPasswordPlaceholder: "Enter your new password",
        passwordResetConfirmPasswordLabel: "Confirm new password",
        passwordResetConfirmPasswordPlaceholder: "Confirm your new password",
        passwordResetSuccessTitle: "Password update successful!",
        passwordResetSuccessMessage: "Log in to the dashboard with your email and the password you just updated.",
        storesListDescription: "Select the store you want to access",

        btnSend: "Send",
        btnLogIn: "Log in",
        password: "Password",
        recoverPassword: "Recover password",
        backToLoginPage: "Back to login page",
        weWillSendYouAnEmailWithInstructions:
            "If you've forgotten your password, we'll send you an email with instructions on how to reset it.",

        // Home
        sales: "Sales",
        orders: "Orders",
        daily: "Daily",
        weekly: "Weekly",
        monthly: "Monthly",
        totalSalesChartTitle: "Total sales",
        totalOrdersChartTitle: "Total orders",
        recentOrdersDataTableTitle: "Recent orders",
        lastSevenDays: "Last 7 days",
        lastFifteenDays: "Last 15 days",
        lastThirtyDays: "Last 30 days",
        topCategoriesChartTitle: "Top categories",
        topProductCardTitle: "Top products",
        averageTicketTotal: "Total ticket",
        averageTicketAndroid: "Average Ticket (Android)",
        averageTicketIOS: "Average Ticket (IOS)",
        lastThreeMonths: "Last 3 months",
        lastSixMonths: "Last 6 months",
        ordersFromTheLastThirtyDays: "Orders from the last 30 days",

        // Showcases
        showcase: "Showcase",


        // Categories
        category: "Category",
        categorySync: "Sync with your source dashboard",
        btnViewList: "View list",
        btnViewTree: "View tree",
        whichCategory: "Which category?",
        parentCategory: "Parent category",
        whatIsTheSearchTerm: "What is the search term?",
        whichProductCollection: "Which product collection?",
        whatIsTheTypeOfTheAction: "What is the type of the action?",

        // Integrations
        integrations: "Integrations",
        integrationClientId: "Client ID",
        integrationSandbox: "Sandbox",
        integrationSecret: "Secret",


        // Users
        user: "User",
        basics: "Basics",
        personalInformation: "Personal information",
        securitySettings: "Security settings",
        passwordConfirm: "Password confirm",
        passwordConfirmError: "Password confirm must match the password field",

        // Logs
        payloadDiff: "Payload diff",


        // Others
        filter: "Filter",
        yes: "Yes",
        no: "No",
        exit: "Exit",
        search: "Search",
        viewProfile: "View profile",
        stores: "Stores",
        developedBy: "Developed by",
        path: "Path",
        accountSettings: "Account settings",
        selectAnOption: "Select an option",


        // Product selector 
        productGridCategories: "Select the categories that have this grid",
        productSpecCategories: "Select the categories that have this specification",



        // Validation
        validationTypeNumber: "Enter a numerical value"
    },
};
