import axios from "axios"
import { Settings } from "modules/configurations/environment/Settings"

const AxiosClient = () =>
{
    const instance = axios.create({
        baseURL: Settings.api.url,
        headers: Settings.api.defaultHeaders,
        withCredentials: true,
    })

    return { instance }
}

export default AxiosClient
