import { handleApiError, isNil } from "lean-util/LeanHelpers"
import LeanRoutes from "lean-util/LeanRoutes"
import { useAdminService } from "modules/admin/useAdminService"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { useFormWithValidation } from "./AdminFormViewData"

export function useViewModel()
{
    const [admin, setAdmin] = useState(null)
    const [isLoading, setIsLoading] = useState()
    const [isSaving, setIsSaving] = useState()

    const adminService = useAdminService()
    const form = useFormWithValidation(admin)
    const history = useHistory()
    const { id } = useParams()

    const goBack = () =>
    {
        history.push(LeanRoutes.adminList)
    }

    const fetchAdmin = async (id) =>
    {
        try
        {
            setIsLoading(true)
            setAdmin((await adminService.fetchAdmin({ id })).data)
            setIsLoading(false)
        }
        catch (error)
        {
            setIsLoading(false)
            handleApiError(error)
        }
    }

    const saveAdmin = async (formData) =>
    {
        try
        {
            setIsSaving(true)

            if (id)
            {
                await adminService.updateAdmin(formData)
            }
            else
            {
                await adminService.insertAdmin(formData)
            }

            setIsSaving(false)
            goBack()
        }
        catch (error)
        {
            handleApiError(error)
            setIsSaving(false)
        }
    }

    useEffect(() =>
    {
        if (id)
            fetchAdmin(id)

    }, [id])

    return {
        admin,
        form,
        isSaving,
        isLoading,
        goBack,
        saveAdmin
    }
}
