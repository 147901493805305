import Head from "layout/head/Head"
import { UICard, UIContent, UIDataTable, UIPageTitle } from "components"
import { useViewModel } from "./StoreReportViewModel"
import { LeanSelect } from "lean-components/LeanComponents"

const StoreReportView = () =>
{
    const viewModel = useViewModel()

    return (
        <>
            <Head title="users" />

            <UIContent>
                <UIPageTitle title="reportPageTitle" description="reportPageDescription">
                    <div className="d-flex" style={{ width: 400 }}>
                        <div className="flex-fill mr-2">
                            <LeanSelect placeholder="Selecione o ano" isLoading={viewModel.isLoadingOptions} options={viewModel.years} onChange={(option) => viewModel.setSelectedYear(option.value)} />
                        </div>
                        <div className="flex-fill">
                            <LeanSelect placeholder="Selecione a loja" isLoading={viewModel.isLoadingOptions} options={viewModel.stores} onChange={(option) => viewModel.setSelectedStoreId(option.value)} />
                        </div>
                    </div>
                </UIPageTitle>

                <UICard>
                    <UIDataTable columns={viewModel.storeReportColumns} data={viewModel.storeReportData} isLoading={viewModel.isLoading} />
                </UICard>
            </UIContent>
        </>
    )
}

export default StoreReportView
