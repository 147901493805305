import Head from "layout/head/Head"
import { UIButton, UICard, UIContent, UIDataTable, UIIcon, UIPageTitle, UITranslator } from "components"
import { useViewModel } from "./AdminListViewModel"

const AdminListView = () =>
{
    const viewModel = useViewModel()

    const getPageTitleRightContent = () =>
    {
        return (
            <UIButton color="primary" onClick={viewModel.redirectToForm}>
                <UIIcon className="mr-1" name="plus" size="sm" />
                <UITranslator id="btnAdd" />
            </UIButton>
        )
    }

    return (
        <>
            <Head title="users" />

            <UIContent>
                <UIPageTitle title="adminPageTitle" description="adminPageDescription" rightContent={getPageTitleRightContent()} />
                <UICard>
                    <UIDataTable columns={viewModel.adminsColumns} data={viewModel.adminsData} isLoading={viewModel.isLoading} onPageChange={viewModel.setPaging} paging={viewModel.paging} />
                </UICard>
            </UIContent>
        </>
    )
}

export default AdminListView
