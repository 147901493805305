import { useAdminAPI } from "./useAdminAPI"

export function useAdminService()
{
    // MARK: - Properties

    const adminAPI = useAdminAPI()


    // MARK: - Methods

    const deleteAdmin = async (parameters) =>
    {
        return await adminAPI.deleteAdmin(parameters)
    }

    const fetchAdmin = async (parameters) =>
    {
        return await adminAPI.fetchAdmin(parameters)
    }

    const fetchAdminList = async (parameters) =>
    {
        return await adminAPI.fetchAdminList(parameters)
    }

    const fetchCurrentAdmin = async (parameters) =>
    {
        return await adminAPI.fetchCurrentAdmin(parameters)
    }

    const insertAdmin = async (parameters) =>
    {
        return await adminAPI.insertAdmin(parameters)
    }

    const updateAdmin = async (parameters) =>
    {
        return await adminAPI.updateAdmin(parameters)
    }

    const updateAdminActiveStatus = async (parameters) =>
    {
        return await adminAPI.updateAdminActiveStatus(parameters)
    }

    return {
        deleteAdmin,
        fetchAdmin,
        insertAdmin,
        updateAdmin,
        fetchAdminList,
        fetchCurrentAdmin,
        updateAdminActiveStatus,
    }
}
