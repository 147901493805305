import { useContext } from "react";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";

import { languages } from "utils/Utils";
import { messages } from "locales/messages";
import { LanguageContext } from "contexts/LanguageContext";

const LeanFlagSelect = () => {
  const { selectedLanguage, handleLanguageChange } = useContext(LanguageContext);

  return (
    <div className="leanFlag-selectWrapper">
      <UncontrolledDropdown className="language-dropdown">
        <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
          <div className="lean-languageBox">
            <img
              className="lean-flagIcon active"
              src={`/assets/${selectedLanguage.img}`}
              alt={selectedLanguage.imgAlt}
            />
          </div>
        </DropdownToggle>

        <DropdownMenu right className="dropdown-menu-sm dropdown-menu-s2">
          <div className="dropdown-body">
            <ul className="lean-languageList">
              {Object.entries(languages).map(
                ([languageKey, languageValue]) =>
                  languageValue.code !== selectedLanguage.code && (
                    <li
                      key={languageKey}
                      className="lean-languageItem"
                      onClick={() => handleLanguageChange(languageValue.urlLang)}
                    >
                      <img
                        className="lean-flagIcon"
                        src={`/assets/${languageValue.img}`}
                        alt={messages[languageValue.imgAlt]}
                      />
                    </li>
                  )
              )}
            </ul>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default LeanFlagSelect;
