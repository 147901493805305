const getAdminPath = (path) =>
{
    return path
}

const LeanPaths =
{
    auth: {
        login: getAdminPath("/login"),
        passwordRecovery: getAdminPath("/password-recovery"),
        passwordReset: getAdminPath("/resend-password"),
    },

    adminListPath: getAdminPath("/admins"),
    adminAddPath: getAdminPath("/admins/add"),
    adminEditPath: getAdminPath("/admins/edit/:id"),

    home: getAdminPath("/"),

    storeListPath: getAdminPath("/stores"),
    storeAddPath: getAdminPath("/stores/add"),
    storeEditPath: getAdminPath("/stores/edit/:id"),

    storeReportPath: getAdminPath("/store-report"),

    syncListPath: getAdminPath("/syncs"),
    syncAddPath: getAdminPath("/syncs/add")
}

export default LeanPaths
