import { generatePath } from 'react-router'
import LeanPaths from "./LeanPaths"

const createPath = (path, params) => generatePath(path, params)

const LeanRoutes =
{
    adminList: createPath(LeanPaths.adminListPath),
    adminAdd: createPath(LeanPaths.adminAddPath),
    adminEdit: (id) => createPath(LeanPaths.adminEditPath, { id }),

    authLogin: createPath(LeanPaths.auth.login),
    authPasswordRecovery: createPath(LeanPaths.auth.passwordRecovery),
    authPasswordReset: createPath(LeanPaths.auth.passwordReset),

    home: createPath(LeanPaths.home),

    storeList: createPath(LeanPaths.storeListPath),
    storeAdd: createPath(LeanPaths.storeAddPath),
    storeEdit: (id) => createPath(LeanPaths.storeEditPath, { id }),

    storeReport: createPath(LeanPaths.storeReportPath),

    syncList: createPath(LeanPaths.syncListPath),
    syncAdd: createPath(LeanPaths.syncAddPath),
}

export default LeanRoutes
