const { Spinner } = require("reactstrap");

const LeanLoader = ({ show }) => {
  return (
    <div className={`lean-loader-backdrop ${show ? "show" : ""}`}>
      <Spinner type="grow" color="primary" />
    </div>
  );
};

export default LeanLoader;
