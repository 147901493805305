import React from "react"

const UIContent = ({ children, type, ...props }) =>
{
    return (
        <div className="nk-content">
            <div className="container-fluid">
                {type === "form" ? (<div className="components-preview wide-xl mx-auto">{children}</div>) : children}
            </div>
        </div>
    )
}

export default UIContent
