import { forwardRef } from "react";
import ReactSelect from "react-select";
import { Controller } from "react-hook-form";

import LeanContentLoader from "./LeanContentLoader";
import { UITranslator } from "components"
import { UICol } from "components";
import { FormGroup } from "reactstrap";

const LeanSelectControl = forwardRef(({ id, inline, note, className, label, error, value, options, isLoading, ...props }, ref) =>
{
    // MARK: - Properties

    const selectedValue = (options ?? []).find((x) => x.value === String(value));

    // MARK: - Methods

    const getDefaultComponent = () =>
    {
        return (
            <>
                <div className="form-label-group">
                    <label className="form-label" htmlFor={id}>
                        {label}
                    </label>
                </div>
                {getSelect()}
            </>
        )
    }

    const getInlineComponent = () =>
    {
        return (
            <>
                <UICol lg="6">
                    <FormGroup>
                        <label className="form-label" htmlFor={id}>{label}</label>
                        {note && (<span className="form-note">{note}</span>)}
                    </FormGroup>
                </UICol>
                <UICol lg="6">
                    <FormGroup>
                        {getSelect()}
                    </FormGroup>
                </UICol>
            </>
        )
    }

    const getSelect = () =>
    {
        if (isLoading)
            return <LeanContentLoader type="component" />

        return (
            <div className="form-control-wrap">
                <div className="form-control-select">
                    <ReactSelect
                        id={id}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder={props.placeholder ?? <UITranslator id="selectAnOption" />}
                        inputRef={ref}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        options={options ?? []}
                        value={selectedValue}
                        {...props}
                    />
                </div>
                {error && <span className="invalid">{error.message}</span>}
            </div>
        )

    }

    return inline ? getInlineComponent() : getDefaultComponent()

})

const LeanSelect = ({ id, inline, control = null, onChange = null, ...props }) =>
{
    const getControl = (field) =>
    {
        return (
            <LeanSelectControl
                id={id}
                inline={inline}
                ref={field?.ref}
                value={field?.value}
                onChange={(val) => onSelectOption(field, val)}
                options={props.options}
                {...props}
            />
        );
    };

    const onSelectOption = (field, val) =>
    {
        field?.onChange(val.value);

        if (onChange) onChange(val);
    };

    if (control === null) return getControl();

    return <Controller control={control} name={id} render={({ field }) => getControl(field)} />;
};

export default LeanSelect;
