import React from "react";
import { UIRow, UICol } from "../../components";

const AuthFooter = () =>
{
    return (
        <div className="nk-footer nk-auth-footer-full">
            <div className="container wide-lg">
                <UIRow className="g-3">
                    <UICol lg="12">
                        <div className="nk-block-content text-center">
                            <p className="text-soft">
                                &copy; 2024 Painel Administrativo | <a target="_blank" rel="noreferrer" href="#">Lean App</a>
                            </p>
                        </div>
                    </UICol>
                </UIRow>
            </div>
        </div>
    );
};

export default AuthFooter;
