import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { AuthContext } from "contexts/AuthContext";
import LeanRoutes from "../../../lean-util/LeanRoutes";
import AuthService from "../../../modules/auth/AuthService";

export default function LoginViewModel()
{
    // MARK: - Properties

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { signIn } = useContext(AuthContext);

    const authService = AuthService();
    const history = useHistory();

    // MARK: - Methods

    const getLoginParameters = (formData) =>
    {
        return {
            user: formData.email,
            password: formData.password,
        };
    };

    const handleLogin = (response) =>
    {
        signIn(response);
        history.push(LeanRoutes.home);
    };

    const onSubmit = async (formData) =>
    {
        try
        {
            setIsLoading(true);
            var response = await authService.login(getLoginParameters(formData));

            setIsLoading(false);
            handleLogin(response.data);
        }
        catch (response)
        {
            setIsLoading(false);
            setErrorMessage(response.error.data.message);
        }
    };

    // MARK: - Public

    return {
        errorMessage,
        isLoading,
        onSubmit,
    };
}
