import APIErrorHandler from "utils/APIErrorHandler"
import HttpClient from "modules/networking/http-client/HttpClient"
import HttpHeaderManager from "modules/networking/managers/HttpHeaderManager"

export function useAdminAPI()
{
    // MARK: - Properties

    const headerManager = HttpHeaderManager()


    // MARK: - Methods

    const deleteAdmin = async (parameters) =>
    {
        try { return await HttpClient.delete("/v1/admin", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const fetchAdmin = async (parameters) =>
    {
        try { return await HttpClient.get("/v1/admin", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const fetchCurrentAdmin = async (parameters) =>
    {
        try { return await HttpClient.get("/v1/admin/current", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const fetchAdminList = async (parameters) =>
    {
        try { return await HttpClient.get("/v1/admin/list", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const insertAdmin = async (parameters) =>
    {
        try { return await HttpClient.post("/v1/admin", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const updateAdmin = async (parameters) =>
    {
        try { return await HttpClient.put("/v1/admin", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const updateAdminActiveStatus = async (parameters) =>
    {
        try { return await HttpClient.put("/v1/admin/active", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    return {
        deleteAdmin,
        fetchAdmin,
        insertAdmin,
        updateAdmin,
        fetchAdminList,
        fetchCurrentAdmin,
        updateAdminActiveStatus,
    }
}
