import { useTranslator } from "lean-util/LeanTranslator"

const useColumns = () =>
{
    // MARK: - Properties

    const { translate } = useTranslator()

    return [
        {
            accessorKey: "indicator",
            header: () => translate("name"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "january",
            header: () => translate("january"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "february",
            header: () => translate("february"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "march",
            header: () => translate("march"),
            cell: info => info.getValue()
        }
        ,
        {
            accessorKey: "april",
            header: () => translate("april"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "may",
            header: () => translate("may"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "june",
            header: () => translate("june"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "july",
            header: () => translate("july"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "august",
            header: () => translate("august"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "september",
            header: () => translate("september"),
            cell: info => info.getValue()
        }
        ,
        {
            accessorKey: "october",
            header: () => translate("october"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "november",
            header: () => translate("november"),
            cell: info => info.getValue()
        },
        {
            accessorKey: "december",
            header: () => translate("december"),
            cell: info => info.getValue()
        }
    ]
}

export { useColumns }
