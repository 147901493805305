import AxiosClient from "./AxiosClient"

const { instance: client } = AxiosClient()

const httpDelete = async (url, parameters, headers) =>
{
    return await client.delete(url, {
        headers: { ...headers },
        params: parameters,
    })
}

const httpGet = async (url, parameters, headers) =>
{
    return await client.get(url, { headers: { ...headers }, params: parameters })
}

const httpPost = async (url, parameters, headers) =>
{
    return await client.post(url, parameters, { headers: { ...headers } })
}

const httpPut = async (url, parameters, headers) =>
{
    return await client.put(url, parameters, { headers: { ...headers } })
}

const httpPatch = async (url, parameters, headers) =>
{
    return await client.patch(url, parameters, { headers: { ...headers } })
}

const HttpClient = {
    delete: httpDelete,
    get: httpGet,
    post: httpPost,
    put: httpPut,
    patch: httpPatch,
}

export default HttpClient
