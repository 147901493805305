import React from "react"
import { flexRender } from '@tanstack/react-table'
import UIDataTableCellEmpty from "components/data-table-cell/UIDataTableCellEmpty"

const UIDataTableView = ({ isLoading, table }) =>
{
    const getDefaultRowState = () =>
    {
        return table.getRowModel().rows.map(row => (
            <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                ))}
            </tr>
        ))
    }

    const getEmptyRowState = () =>
    {
        return (
            <tr>
                <td colSpan={table.getAllColumns().length}>
                    <UIDataTableCellEmpty />
                </td>
            </tr>
        )
    }

    return (
        <table className="dataTable">
            <thead>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id} style={{ width: `${header.getSize()}px` }}>
                                {header.isPlaceholder ? null : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowCount() === 0 && !isLoading ? getEmptyRowState() : getDefaultRowState()}
            </tbody>
        </table>
    )
}

export default UIDataTableView
