import { useTranslator } from "lean-util/LeanTranslator"

const UIPageTitle = ({ title, description, rightContent, ...props }) =>
{
    const { translate } = useTranslator()

    return (
        <div className="d-flex justify-content-between align-items-center pb-4">
            <div>
                <h3>{translate(title)}</h3>
                {description && (<p className="text-soft">{translate(description)}</p>)}
            </div>

            {(rightContent || props.children) && (
                <div>{rightContent ?? props.children}</div>
            )}
        </div>
    )
}

export default UIPageTitle
