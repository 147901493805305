import { useStoreAPI } from "./useStoreAPI"

export function useStoreService()
{
    // MARK: - Properties

    const storeAPI = useStoreAPI()


    // MARK: - Methods

    const deleteStore = async (parameters) =>
    {
        return await storeAPI.deleteStore(parameters)
    }

    const fetchStore = async (parameters) =>
    {
        return await storeAPI.fetchStore(parameters)
    }

    const fetchStoreList = async (parameters) =>
    {
        return await storeAPI.fetchStoreList(parameters)
    }

    const fetchStoreReport = async (parameters) =>
    {
        return await storeAPI.fetchStoreReport(parameters)
    }

    const insertStore = async (parameters) =>
    {
        return await storeAPI.insertStore(parameters)
    }

    const updateStore = async (parameters) =>
    {
        return await storeAPI.updateStore(parameters)
    }

    return {
        deleteStore,
        fetchStore,
        insertStore,
        updateStore,
        fetchStoreList,
        fetchStoreReport
    }
}
