import { format, formatISO, parseISO } from "date-fns";
import { LeanAlert } from "lean-components/LeanComponents";
import { cloneDeep } from "lodash";
import _uniqueId from "lodash/uniqueId";

const currencyFormat = (value) =>
{
    if (isNilOrEmpty(value)) return null;

    return value.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
};

const copy = (value) =>
{
    if (isNotNil(value))
        return cloneDeep(value)

    return null
}

const formatDate = (date, dateFormat) =>
{
    if (date && dateFormat) return format(date, dateFormat);

    return null;
};

const formatDateToISO = (date) =>
{
    if (date) return formatISO(date);

    return null;
};

const getDateTime = (date, dateWithTime) =>
{
    if (isNil(date))
        return null

    if (isNil(dateWithTime))
        return formatDateToISO(date)

    return formatDateToISO(new Date(
        date.getFullYear(), date.getMonth(), date.getDate(),
        dateWithTime.getHours(), dateWithTime.getMinutes(), dateWithTime.getSeconds()
    ))
}

const getImageSize = (source) =>
{
    return new Promise((resolved, rejected) =>
    {
        const image = new Image();

        image.onload = () =>
        {
            resolved({
                height: image.naturalHeight,
                width: image.naturalWidth,
            })
        };

        image.onerror = () =>
        {
            rejected()
        }

        image.src = source
    })
}

const getUniqueId = (prefix) =>
{
    return _uniqueId(prefix);
};

const handleApiError = ({ error: { data, status } = {} }) =>
{
    LeanAlert.error(data?.message ?? "Ocorreu um erro durante o processamento.");
};

const isNil = (prop) =>
{
    return prop === undefined || prop === null;
};

const isNilOrEmpty = (prop) =>
{
    return prop === undefined || prop === null || prop.length === 0;
};

const isNotNil = (prop) =>
{
    return !isNil(prop);
};

const parseToInt = (stringValue) =>
{
    if (stringValue) return parseInt(stringValue)

    return null;

}

const parseToISODate = (stringDate) =>
{
    if (stringDate) return parseISO(stringDate);

    return null;
};

const dataTableActionTypes = {
    onReset: 'reset',
    onSearch: 'search',
    onChangePage: "change_page",
    onChangeSort: "change_sort",
    onChangePageSize: "change_pageSize",
};

const dataTableInitialValues = {
    page: 1,
    pageSize: 10,
    sortField: null,
    sortDirection: null,
};

const dataTableFormReducer = (state, action) =>
{
    switch (action.type)
    {
        case dataTableActionTypes.onChangePage:
            return {
                ...state,
                page: action.payload.value,
            };
        case dataTableActionTypes.onChangePageSize:
            return {
                ...state,
                pageSize: action.payload.value.newPageSize,
                page: action.payload.value.newPage,
            };
        case dataTableActionTypes.onChangeSort:
            return {
                ...state,
                page: 1,
                sortDirection: action.payload.value.newDirection,
                sortField: action.payload.value.newSortField.sortField,
            };
        case dataTableActionTypes.onSearch:
            return {
                ...dataTableInitialValues,
                ...action.payload.value,
            }
        case dataTableActionTypes.onReset:
            return {
                ...dataTableInitialValues
            }
        default:
            break;
    }
};

export
{
    copy,
    isNil,
    isNotNil,
    formatDate,
    getDateTime,
    getUniqueId,
    getImageSize,
    isNilOrEmpty,
    currencyFormat,
    parseToInt,
    parseToISODate,
    handleApiError,
    formatDateToISO,
    dataTableActionTypes,
    dataTableFormReducer,
    dataTableInitialValues,
};
