const url = `${process.env.NODE_ENV === "development"
        ? "https://localhost:7143"
        : window.location.origin
    }/api`;

const Settings = {
    api: {
        url,
        defaultHeaders: {
            "Content-Type": "application/json",
        },
    },
};

export { Settings };
