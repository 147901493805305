import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { ptForm } from 'yup-locale-pt';
import { yupResolver } from '@hookform/resolvers/yup';

Yup.setLocale(ptForm);

const useFormWithValidation = () => 
{
    const schema = Yup.object().shape({
        newPassword: Yup.string().required(),
        confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], "O campo de confirmação deve ser igual à nova senha.")
    })

    return useForm({ resolver: yupResolver(schema) })
}

export
{
    useFormWithValidation
}
