import React from "react"
import UIDataTablePagination from "./UIDataTablePagination"
import UIDataTableView from "./UIDataTableView"
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { useDataTableHandler } from "./UIDataTableHandlerHook"

const UIDataTable = ({ columns, data, isLoading = false, paging, onPageChange, ...props }) => 
{
    const handler = useDataTableHandler(columns, data, isLoading)

    const table = useReactTable({
        columns: handler.tableColumns,
        data: handler.tableData,
        defaultColumn: handler.defaultColumn,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: paging ? true : false,
        onPaginationChange: onPageChange,
        rowCount: handler.rowCount,
        state: { pagination: paging }
    })

    return (
        <>
            <UIDataTableView isLoading={isLoading} table={table} />

            {paging && (
                <UIDataTablePagination rowCount={handler.rowCount} table={table} />
            )}
        </>
    )
}

export default UIDataTable
