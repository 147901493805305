import LeanRoutes from "lean-util/LeanRoutes"
import { useHistory } from "react-router"
import { handleApiError } from "lean-util/LeanHelpers"
import { useEffect, useState } from "react"
import { useOrderService } from "modules/order/useOrderService"
import { useColumns } from "./OrderSyncListViewData"

export function useViewModel()
{
    // MARK: - Properties

    const [orderSyncsData, setOrderSyncsData] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [paging, setPaging] = useState({ pageIndex: 0, pageSize: 10 })

    const orderService = useOrderService()
    const history = useHistory()


    // MARK: - Methods

    const fetchOrderSyncs = async () =>
    {
        try
        {
            setIsLoading(true)
            setOrderSyncsData((await orderService.fetchOrderSyncList({ ...paging, pageIndex: paging.pageIndex + 1 })).data)
            setIsLoading(false)
        }
        catch (error)
        {
            setIsLoading(false)
            handleApiError(error)
        }
    }

    const redirectToForm = () =>
    {
        history.push(LeanRoutes.syncAdd)
    }

    const refresh = () =>
    {
        fetchOrderSyncs()
    }

    useEffect(() =>
    {
        fetchOrderSyncs()

    }, [paging])

    return {
        orderSyncsColumns: useColumns(),
        orderSyncsData,
        isLoading,
        paging,
        redirectToForm,
        refresh,
        setPaging,
    }
}