import React from "react";
import LeanRoutes from "lean-util/LeanRoutes";
import { Link } from "react-router-dom";
import { ImgLeanLogo, ImgLeanLogoLight, ImgLeanLogoSmall } from "lean-util/LeanImages";

const Logo = ({ storeLogo, storeFavIcon }) =>
{
    return (
        <Link to={LeanRoutes.home} className="logo-link">
            <img className="logo-light logo-img logo-img-md" src={storeLogo ?? ImgLeanLogoLight} alt="logo" />
            <img className="logo-dark logo-img logo-img-md" src={storeLogo ?? ImgLeanLogo} alt="logo-dark" />
            <img className="logo-small logo-img logo-img-small" src={storeFavIcon ?? ImgLeanLogoSmall} alt="logo" />
        </Link>
    );
};

export default Logo;
