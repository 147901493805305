import classNames from "classnames";
import { LeanContentLoader } from "lean-components/LeanComponents";

const UICard = ({ bodyClassName, children, className, isLoading = false, loadingType = "form", ...props }) =>
{
    const cardContainerClassName = classNames({
        [`${className}`]: className,
        "card": true,
        "card-bordered": true,
    })

    const cardBodyClassName = classNames({
        [`${bodyClassName}`]: bodyClassName,
        "card-inner": true,
    })

    const getContent = () =>
    {
        if (isLoading)
            return <LeanContentLoader type={loadingType} />

        return children
    }

    return (
        <div className={cardContainerClassName} {...props}>
            <div className={cardBodyClassName}>
                {getContent()}
            </div>
        </div>
    );
};

export default UICard;
