import { handleApiError, isNilOrEmpty } from "lean-util/LeanHelpers"
import LeanRoutes from "lean-util/LeanRoutes"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { useFormWithValidation } from "./OrderSyncFormViewData"
import { useOrderService } from "modules/order/useOrderService"
import { useFormOptionsService } from "modules/form-options/useFormOptionsService"

export function useViewModel()
{
    const [isSaving, setIsSaving] = useState()
    const [isLoading, setIsLoading] = useState()
    const [stores, setStores] = useState([])

    const form = useFormWithValidation()
    const formOptionsService = useFormOptionsService()
    const history = useHistory()
    const orderService = useOrderService()

    const goBack = () =>
    {
        history.push(LeanRoutes.syncList)
    }

    const saveOrderSync = async (formData) =>
    {
        try
        {
            setIsSaving(true)
            await orderService.insertOrderSync(formData)
            setIsSaving(false)
            goBack()
        }
        catch (error)
        {
            handleApiError(error)
            setIsSaving(false)
        }
    }

    useEffect(() =>
    {
        const fetchStoreOptions = async () =>
        {
            try
            {
                setIsLoading(true)
                setStores((await formOptionsService.fetchStores()).data)
                setIsLoading(false)
            }
            catch (error)
            {
                setIsLoading(false)
                handleApiError(error)
            }
        }

        fetchStoreOptions()

    }, [])

    return {
        form,
        isSaving,
        isLoading,
        goBack,
        saveOrderSync,
        stores
    }
}
