import { useContext } from "react";
import { useIntl } from "react-intl";
import { languages } from "utils/Utils";
import { messages } from "locales/messages";
import { LanguageContext } from "contexts/LanguageContext";

const useTranslator = () =>
{
    // MARK: - Properties

    const intl = useIntl();
    const { selectedLanguage } = useContext(LanguageContext);

    // MARK: - Methods

    const translate = (key) =>
    {
        return messages[selectedLanguage.code][key] ?? key;
    };

    const translateDate = ({ value, year, month, day }) =>
    {
        return intl.formatDate(value, { ...(day && { day }), ...(month && { month }), ...(year && { year }) });
    };

    const translateCurrency = ({ value, language = "pt" }) =>
    {
        return intl.formatNumber(value, { style: "currency", currency: languages[language].currency });
    };

    return {
        translate,
        translateDate,
        translateCurrency,
    };
};

export { useTranslator };
