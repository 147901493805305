import { handleApiError, isNilOrEmpty } from "lean-util/LeanHelpers"
import { useEffect, useState } from "react"
import { useStoreService } from "modules/store/useStoreService"
import { useColumns } from "./StoreReportViewData"
import { useFormOptionsService } from "modules/form-options/useFormOptionsService"

export function useViewModel()
{
    // MARK: - Properties

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingOptions, setIsLoadingOptions] = useState(false)
    const [selectedYear, setSelectedYear] = useState()
    const [selectedStoreId, setSelectedStoreId] = useState()
    const [stores, setStores] = useState()
    const [storeReportData, setStoreReportData] = useState()
    const [years, setYears] = useState()

    const formOptionsService = useFormOptionsService()
    const storeService = useStoreService()


    // MARK: - Methods

    useEffect(() =>
    {
        const fetchOptions = async () =>
        {
            try
            {
                setIsLoadingOptions(true)
                setStores((await formOptionsService.fetchStores()).data)
                setYears((await formOptionsService.fetchYears()).data)
                setIsLoadingOptions(false)
            }
            catch (error)
            {
                setIsLoadingOptions(false)
                handleApiError(error)
            }
        }

        fetchOptions()

    }, [])

    useEffect(() =>
    {
        const fetchStoreReport = async () =>
        {
            if (isNilOrEmpty(selectedStoreId) || isNilOrEmpty(selectedYear))
                return

            try
            {
                setIsLoading(true)
                setStoreReportData((await storeService.fetchStoreReport({ storeId: selectedStoreId, year: selectedYear })).data)
                setIsLoading(false)
            }
            catch (error)
            {
                setIsLoading(false)
                handleApiError(error)
            }
        }

        fetchStoreReport()

    }, [selectedStoreId, selectedYear])

    return {
        isLoading,
        isLoadingOptions,
        setSelectedStoreId,
        setSelectedYear,
        stores,
        storeReportColumns: useColumns(),
        storeReportData,
        years
    }
}