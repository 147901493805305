import { createContext, useState } from "react";
import { parseToISODate } from "lean-util/LeanHelpers";
import { useCookies } from "react-cookie";

export const AuthContext = createContext({});

const AuthProvider = ({ children }) =>
{
    const [cookie, setCookie, removeCookie] = useCookies(["lwApp-user", "lwApp-token", "lwApp-storeCode"]);
    const [user, setUser] = useState(cookie["lwApp-user"] || null);
    const [token, setToken] = useState(cookie["lwApp-token"] || null);

    const setUserData = (value, settings) =>
    {
        setUser(value);
        setCookie("lwApp-user", JSON.stringify(value), settings);
    };

    const setTokenData = (value, settings) =>
    {
        setToken(value);
        setCookie("lwApp-token", value, settings);
    };

    const signIn = (loginResponse) =>
    {
        signOut();

        const authExpiration = loginResponse.auth.expiration
        const authToken = loginResponse.auth.token
        const admin = loginResponse.admin

        const cookieSettings = {
            expiration: parseToISODate(authExpiration),
            path: "/",
        };

        setUserData(admin, cookieSettings);
        setTokenData(authToken, cookieSettings);
    };

    const signOut = () =>
    {
        setUser(null);
        setToken(null);
        removeCookie("lwApp-user");
        removeCookie("lwApp-token");
    };

    const isAuthenticated = !!user && !!token;

    return (
        <AuthContext.Provider value={{
            user,
            token,
            signIn,
            signOut,
            setUserData,
            setTokenData,
            isAuthenticated,
        }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider };
