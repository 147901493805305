import Head from "layout/head/Head";
import { Form, FormGroup } from "reactstrap";
import { UIButton, UITranslator, UIContent, UIPageTitle, UICard, UIRow, UICol } from "components";
import { LeanDatePicker, LeanSelect } from "lean-components/LeanComponents";
import { useViewModel } from "./OrderSyncFormViewModel";
import { useTranslator } from "lean-util/LeanTranslator";

const OrderSyncFormView = () =>
{
    const viewModel = useViewModel()
    const form = viewModel.form
    const formErrors = form.formState.errors
    const { translate } = useTranslator()

    return (
        <>
            <Head title="orderSyncPageTitle" />

            <UIContent type="form">

                <UIPageTitle title="orderSyncPageTitle" description="orderSyncPageDescription">
                    <UIButton outline type="button" color="primary" className="mr-2" onClick={viewModel.goBack}>
                        <UITranslator id="btnBack" />
                    </UIButton>
                    <UIButton form="form" type="submit" color="primary" isLoading={viewModel.isSaving}>
                        <UITranslator id="sync" />
                    </UIButton>
                </UIPageTitle>

                <UICard isLoading={viewModel.isLoading} loadingType="form">
                    <Form id="form" onSubmit={form.handleSubmit(viewModel.saveOrderSync)}>

                        <UIRow>
                            <UICol lg="4">
                                <LeanSelect id="storeId" label={translate("store")} control={form.control} error={formErrors.storeId} options={viewModel.stores} />
                            </UICol>
                        </UIRow>

                        <UIRow>
                            <UICol lg="4">
                                <FormGroup>
                                    <label className="form-label" htmlFor="from">{translate("from")}</label>
                                    <LeanDatePicker id="from" register={form.register} error={formErrors.from} onChange={(value) =>
                                    {
                                        form.setValue("from", value.toJSON())
                                        form.trigger("from")
                                    }} />
                                </FormGroup>
                            </UICol>
                            <UICol lg="4">
                                <FormGroup>
                                    <label className="form-label" htmlFor="to">{translate("to")}</label>
                                    <LeanDatePicker id="to" register={form.register} error={formErrors.to} onChange={(value) =>
                                    {
                                        form.setValue("to", value.toJSON())
                                        form.trigger("to")
                                    }} />
                                </FormGroup>
                            </UICol>
                        </UIRow>

                    </Form>
                </UICard>

            </UIContent>
        </>
    )
}

export default OrderSyncFormView
