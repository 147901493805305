import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { ptForm } from "yup-locale-pt"
import { yupResolver } from "@hookform/resolvers/yup"

Yup.setLocale(ptForm)

const getDefaultValues = () =>
{
    return {}
}

const useFormWithValidation = (store) =>
{
    let schema

    if (store)
    {
        schema = Yup.object().shape({
            name: Yup.string().required(),
            url: Yup.string().url().required()
        })
    }
    else
    {
        schema = Yup.object().shape({
            key: Yup.string().required(),
            name: Yup.string().required(),
            token: Yup.string().required(),
            url: Yup.string().url().required(),
        })
    }

    return useForm({
        defaultValues: getDefaultValues(),
        resolver: yupResolver(schema)
    })
}

export { useFormWithValidation }
