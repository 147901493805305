import { LeanAlert } from "lean-components/LeanComponents"
import { isNilOrEmpty } from "lean-util/LeanHelpers"
import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router"
import { useTranslator } from "lean-util/LeanTranslator"
import LeanRoutes from "lean-util/LeanRoutes"
import AuthService from "modules/auth/AuthService"

export default function PasswordResetViewModel()
{
    // MARK: - Properties

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const authService = AuthService()
    const history = useHistory()
    const translator = useTranslator()
    const token = new URLSearchParams(useLocation().search).get("t")


    // MARK: - Methods

    const getPassworResetParameters = (formData) =>
    {
        return {
            confirmPassword: formData.confirmPassword,
            newPassword: formData.newPassword,
            token: token
        }
    }

    const redirectIfNeed = () =>
    {
        if (isNilOrEmpty(token))
            redirectToLogin()
    }

    const redirectToLogin = () =>
    {
        history.push(LeanRoutes.authLogin)
    }

    const send = async (formData) =>
    {
        try
        {
            setIsLoading(true)
            await authService.resetPassword(getPassworResetParameters(formData))
            setIsLoading(false)

            LeanAlert.success({
                title: translator.translate("passwordResetSuccessTitle"),
                text: translator.translate("passwordResetSuccessMessage"),
                onConfirm: () => { redirectToLogin() }
            })
        }
        catch (response)
        {
            setIsLoading(false)
            setErrorMessage(response.error.data.message)
        }
    }


    // MARK: - Callbacks

    useEffect(() => { redirectIfNeed() })


    // MARK: - Public

    return {
        errorMessage,
        isLoading,
        send
    }
}
