import React, { useContext, useState } from "react";
import LeanPaths from "lean-util/LeanPaths";
import { AuthContext } from "contexts/AuthContext";
import { UITranslator } from "components"
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { UIIcon } from "../../../../components";
import { LinkList, LinkItem } from "../../../../components/links/Links";

const User = () =>
{
    const {
        user: { name, email, isAdmin, isMaster, storesIds },
        signOut,
    } = useContext(AuthContext);

    const [open, setOpen] = useState(false);
    const toggle = () => setOpen((prevState) => !prevState);

    return (
        <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
            <DropdownToggle
                tag="a"
                href="#toggle"
                className="dropdown-toggle"
                onClick={(ev) =>
                {
                    ev.preventDefault();
                }}
            >
                <div className="user-toggle">
                    <div className="user-avatar">
                        <span>
                            {name.split(" ").map((string) => string[0]).join("")}
                        </span>
                    </div>
                    {/* <UserAvatar icon="user-alt" className="sm" /> */}
                    <div className="user-info d-none d-md-block">
                        <div className="user-status">
                            <UITranslator id={isAdmin ? "administrator" : "user"} />
                        </div>
                        <div className="user-name dropdown-indicator">{name}</div>
                    </div>
                </div>
            </DropdownToggle>
            <DropdownMenu right className="dropdown-menu-md dropdown-menu-s1">
                <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                    <div className="user-card sm">
                        <div className="user-avatar">
                            <span>
                                {name
                                    .split(" ")
                                    .map((string) => string[0])
                                    .join("")}
                            </span>
                        </div>
                        <div className="user-info">
                            <span className="lead-text">{name}</span>
                            <span className="sub-text">{email}</span>
                        </div>
                    </div>
                </div>
                <div className="dropdown-inner">
                    <LinkList>
                        <LinkItem link={LeanPaths.userSettingsPath} icon="setting-alt" onClick={toggle}>
                            <UITranslator id="accountSettings" />
                        </LinkItem>
                    </LinkList>
                </div>
                <div className="dropdown-inner">
                    <LinkList>
                        <a href={`${process.env.PUBLIC_URL}/login`} onClick={signOut}>
                            <UIIcon name="signout"></UIIcon>
                            <span>
                                <UITranslator id="exit" />
                            </span>
                        </a>
                    </LinkList>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

export default User;
