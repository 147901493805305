import { Link } from "react-router-dom"
import { Form, FormGroup, Alert } from "reactstrap"
import AuthFooter from "../AuthFooter"
import LeanPaths from "lean-util/LeanPaths"
import Head from "../../../layout/head/Head"
import LoginViewModel from "./LoginViewModel"
import { useFormWithValidation } from "./LoginViewData"
import { useTranslator } from "lean-util/LeanTranslator"
import { ImgLeanLogo, ImgLeanLogoLight } from "lean-util/LeanImages"
import { LeanFlagSelect, LeanInput } from "../../../lean-components/LeanComponents"
import { UIAuthContainer, UIButton, UICard, UIIcon, UITranslator } from "components"


const LoginView = () =>
{
    // MARK: - Properties

    const viewModel = LoginViewModel()
    const { translate } = useTranslator()
    const { register, handleSubmit, formState: { errors } } = useFormWithValidation()


    // MARK: - Methods

    const passwordLabelAccessory = (
        <Link className="link link-primary link-sm" tabIndex="-1" to={LeanPaths.auth.passwordRecovery}>
            <UITranslator id="logInPasswordLabelAccessory" />
        </Link>
    )

    // MARK: - Render

    return (
        <>
            <Head title="Login" />

            <LeanFlagSelect />

            <UIAuthContainer>
                <div className="nk-block-middle nk-auth-body wide-xs">
                    <div className="brand-logo pb-4 text-center">
                        <Link to={LeanPaths.auth.login} className="logo-link">
                            <img className="logo-light logo-img logo-img-lg" src={ImgLeanLogoLight} alt="logo" />
                            <img className="logo-dark logo-img logo-img-lg" src={ImgLeanLogo} alt="logo-dark" />
                        </Link>
                    </div>

                    <UICard bodyClassName="card-inner-lg">
                        <h4>Login</h4>
                        <p className="text-soft">
                            <UITranslator id="logInDescription" />
                        </p>

                        {viewModel.errorMessage && (
                            <div className="mb-3">
                                <Alert color="danger" className="alert-icon">
                                    <UIIcon name="alert-circle" /> {viewModel.errorMessage}
                                </Alert>
                            </div>
                        )}

                        <Form onSubmit={handleSubmit(viewModel.onSubmit)}>
                            <FormGroup>
                                <LeanInput type="text" id="email" placeholder={translate("logInEmailPlaceholder")} className="form-control-lg" label="E-mail*" register={register} error={errors.email} />
                            </FormGroup>
                            <FormGroup>
                                <LeanInput type="password" id="password" placeholder={translate("logInPasswordPlaceholder")} className="form-control-lg" label={
                                    <>
                                        <UITranslator id="password" />*
                                    </>
                                }
                                    labelAccessory={passwordLabelAccessory}
                                    register={register}
                                    error={errors.password}
                                />
                            </FormGroup>
                            <FormGroup>
                                <UIButton type="submit" className="btn-block" color="primary" size="lg" isLoading={viewModel.isLoading}>
                                    <UITranslator id="btnLogIn" />
                                </UIButton>
                            </FormGroup>
                        </Form>
                    </UICard>
                </div>
                <AuthFooter />
            </UIAuthContainer>
        </>
    )
}

export default LoginView
