import { UICol } from "components";
import React from "react";
import { FormGroup } from "reactstrap";

const LeanSwitch = ({ id, label, note, inline, register, ...props }) =>
{
    const getDefaultComponent = () =>
    {
        return (
            <>
                <div className="form-label-group">
                    <label className="form-label" htmlFor={id}>{label}</label>
                </div>
                {getSwitch()}
            </>
        )
    }

    const getInlineComponent = () =>
    {
        return (
            <>
                <UICol lg="6">
                    <FormGroup>
                        <label className="form-label" htmlFor={id}>{label}</label>
                        {note && (<span className="form-note">{note}</span>)}
                    </FormGroup>
                </UICol>
                <UICol lg="6">
                    <FormGroup>
                        {getSwitch()}
                    </FormGroup>
                </UICol>
            </>
        )
    }

    const getSwitch = () =>
    {
        return (
            <div className="form-control-wrap">
                <div className="custom-control custom-switch">
                    <input id={id} type="checkbox" className="custom-control-input form-control" {...(register && register(id))} {...props} />
                    <label className="custom-control-label" htmlFor={id}></label>
                </div>
            </div>
        )
    }

    return (
        <>
            {inline ? getInlineComponent() : getDefaultComponent()}
        </>
    );
};

export default LeanSwitch;
