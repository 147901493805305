import React, { useMemo } from "react"
import Skeleton from "react-loading-skeleton"

export function useDataTableHandler(columns, data, isLoading)
{
    const tableData = useMemo(() =>
    {
        return isLoading ? Array(10).fill({}) : data?.rows ?? []
    }, [data, isLoading])

    const tableColumns = useMemo(() =>
    {
        return isLoading ? columns.map((column) => ({ ...column, cell: () => <Skeleton /> })) : columns
    }, [columns, isLoading]);

    var defaultColumn = {
        size: "auto",
        minSize: 50,
        maxSize: 500,
    }

    return {
        defaultColumn,
        rowCount: data?.count ?? 0,
        tableData,
        tableColumns
    }
}
