import Head from "layout/head/Head"
import { UIButton, UICard, UIContent, UIDataTable, UIIcon, UIPageTitle, UITranslator } from "components"
import { useViewModel } from "./OrderSyncListViewModel"

const OrderSyncListView = () =>
{
    const viewModel = useViewModel()

    return (
        <>
            <Head title="orderSyncPageTitle" />

            <UIContent>
                <UIPageTitle title="orderSyncPageTitle" description="orderSyncPageDescription">
                    <UIButton dim color="primary" className="mr-2" onClick={viewModel.refresh}>
                        <UIIcon className="mr-1" name="reload" size="sm" />
                        <UITranslator id="refresh" />
                    </UIButton>
                    <UIButton color="primary" onClick={viewModel.redirectToForm}>
                        <UIIcon className="mr-1" name="plus" size="sm" />
                        <UITranslator id="btnAdd" />
                    </UIButton>
                </UIPageTitle>
                <UICard>
                    <UIDataTable columns={viewModel.orderSyncsColumns} data={viewModel.orderSyncsData} isLoading={viewModel.isLoading} onPageChange={viewModel.setPaging} paging={viewModel.paging} />
                </UICard>
            </UIContent>
        </>
    )
}

export default OrderSyncListView
