import UIIcon from "components/icon/UIIcon"
import UITranslator from "components/translator/UITranslator"

const UIDataTableCellEmpty = () =>
{

    return (
        <div className="d-flex flex-column align-items-center p-3">
            <UIIcon name="search" size="lg" />
            <p className="text-soft mt-2">
                <UITranslator id="emptyMessage" />
            </p>
        </div>
    )
}

export default UIDataTableCellEmpty
