import classnames from "classnames"

const UICol = ({ sm, md, lg, xl, xxl, size, className, ...props }) =>
{
    var classNames = classnames({
        [`col-sm-${sm}`]: sm,
        [`col-lg-${lg}`]: lg,
        [`col-md-${md}`]: md,
        [`col-xl-${xl}`]: xl,
        [`col-xxl-${xxl}`]: xxl,
        [`col-${size}`]: size,
        [`${className}`]: className,
    })

    return <div className={classNames}>{props.children}</div>
}

export default UICol