import AuthAPI from "./AuthAPI"

export default function AuthService()
{
    // MARK: - Properties

    const authAPI = AuthAPI()


    // MARK: - Methods

    const login = async (parameters) =>
    {
        return await authAPI.login(parameters)
    }

    const requestPasswordReset = async (parameters) =>
    {
        return await authAPI.requestPasswordReset(parameters)
    }

    const resetPassword = async (parameters) =>
    {
        return await authAPI.resetPassword(parameters)
    }

    return {
        login,
        requestPasswordReset,
        resetPassword
    }
}