import { useState } from "react"
import { Tooltip } from "reactstrap"
import { UIButton, UIIcon } from ".."

const UIDataTableCellActions = ({ id, onDelete, onEdit, onView }) =>
{
    // MARK: - Properties

    const [viewTooltipOpen, setViewTooltipOpen] = useState(false)
    const [editTooltipOpen, setEditTooltipOpen] = useState(false)
    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false)

    // MARK: - Actions

    const viewButtonPressed = (event) =>
    {
        event.preventDefault()
        onView(id)
    }

    const editButtonPressed = (event) =>
    {
        event.preventDefault()
        onEdit(id)
    }

    const deleteButtonPressed = (event) =>
    {
        event.preventDefault()
        onDelete(id)
    }

    // MARK: - Methods

    const deleteToggle = () =>
    {
        setDeleteTooltipOpen(!deleteTooltipOpen)
    }

    const editToggle = () =>
    {
        setEditTooltipOpen(!editTooltipOpen)
    }

    const viewToggle = () =>
    {
        setViewTooltipOpen(!viewTooltipOpen)
    }

    // MARK: - Render

    return (
        <div>
            {onView && (
                <>
                    <Tooltip placement="top" isOpen={viewTooltipOpen} target={"view-" + id} toggle={viewToggle}>Visualizar</Tooltip>
                    <UIButton id={"view-" + id} className="btn-icon mr-1" color="info" size="sm" onClick={viewButtonPressed}>
                        <UIIcon name="eye" />
                    </UIButton>
                </>
            )}
            {onEdit && (
                <>
                    <Tooltip placement="top" isOpen={editTooltipOpen} target={"edit-" + id} toggle={editToggle}>Editar</Tooltip>
                    <UIButton id={"edit-" + id} className="btn-icon mr-1" color="warning" size="sm" onClick={editButtonPressed}>
                        <UIIcon name="edit" />
                    </UIButton>
                </>
            )}
            {onDelete && (
                <>
                    <Tooltip placement="top" isOpen={deleteTooltipOpen} target={"delete-" + id} toggle={deleteToggle}>Excluir</Tooltip>
                    <UIButton id={"delete-" + id} className=" btn-icon" color="danger" size="sm" onClick={deleteButtonPressed}>
                        <UIIcon name="trash" />
                    </UIButton>
                </>
            )}
        </div>
    )
}

export default UIDataTableCellActions
