import APIErrorHandler from "utils/APIErrorHandler"
import HttpClient from "modules/networking/http-client/HttpClient"
import HttpHeaderManager from "modules/networking/managers/HttpHeaderManager"

export function useFormOptionsAPI()
{
    // MARK: - Properties

    const headerManager = HttpHeaderManager()

    // MARK: - Methods

    const fetchStores = async (parameters) =>
    {
        try { return await HttpClient.get("/v1/form-options/stores", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const fetchYears = async (parameters) =>
    {
        try { return await HttpClient.get("/v1/form-options/years", parameters, headerManager.authHeaders) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    return {
        fetchStores,
        fetchYears
    }
}
