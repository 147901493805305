const Footer = () =>
{
    return (
        <div className="nk-footer">
            <div className="container-fluid">
                <div className="nk-footer-wrap">
                    <div className="nk-footer-copyright">
                        &copy; 2023 <a href="/">Painel</a>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Footer;
