import LeanRoutes from "lean-util/LeanRoutes"
import { useHistory } from "react-router"
import { handleApiError } from "lean-util/LeanHelpers"
import { useAdminService } from "modules/admin/useAdminService"
import { useEffect, useState } from "react"
import { useColumns } from "./AdminListViewData"

export function useViewModel()
{
    // MARK: - Properties

    const [adminsData, setAdminsData] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [paging, setPaging] = useState({ pageIndex: 0, pageSize: 10 })

    const adminService = useAdminService()
    const history = useHistory()


    // MARK: - Methods

    const deleteAdmin = async (id) =>
    {
        try
        {
            setIsLoading(true)

            await adminService.deleteAdmin({ id: id })

            setAdminsData((await adminService.fetchAdminList(paging)).data)
            setIsLoading(false)
        }
        catch (error)
        {
            setIsLoading(false)
            handleApiError(error)
        }
    }

    const redirectToForm = () =>
    {
        history.push(LeanRoutes.adminAdd)
    }

    const redirectToFormWithId = (id) =>
    {
        history.push(LeanRoutes.adminEdit(id))
    }

    const updateStatus = async (id, isActive) =>
    {
        try
        {
            await adminService.updateAdminActiveStatus({ id, isActive })
        }
        catch (error)
        {
            handleApiError(error)
        }
    }

    useEffect(() =>
    {
        const fetchAdmins = async () =>
        {
            try
            {
                setIsLoading(true)
                setAdminsData((await adminService.fetchAdminList(paging)).data)
                setIsLoading(false)
            }
            catch (error)
            {
                setIsLoading(false)
                handleApiError(error)
            }
        }

        fetchAdmins()

    }, [paging])

    return {
        adminsColumns: useColumns(updateStatus, redirectToFormWithId, deleteAdmin),
        adminsData,
        isLoading,
        paging,
        redirectToForm,
        setPaging,
    }
}