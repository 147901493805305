import Head from "layout/head/Head";
import { Form } from "reactstrap";
import { UIButton, UITranslator, UIContent, UIPageTitle, UICard, UIRow, UICol } from "components";
import { LeanInput } from "lean-components/LeanComponents";
import { useEffect } from "react";
import { useViewModel } from "./StoreFormViewModel";
import { useTranslator } from "lean-util/LeanTranslator";

const StoreFormView = () =>
{
    const viewModel = useViewModel()
    const form = viewModel.form
    const formErrors = form.formState.errors
    const { translate } = useTranslator()

    const getPageTitleRightContent = () =>
    {
        return (
            <>
                <UIButton outline type="button" color="primary" className="mr-2" onClick={viewModel.goBack}>
                    <UITranslator id="btnBack" />
                </UIButton>
                <UIButton form="form" type="submit" color="primary" isLoading={viewModel.isSaving}>
                    <UITranslator id="btnSave" />
                </UIButton>
            </>
        )
    }

    useEffect(() =>
    {
        if (viewModel.store)
            form.reset(viewModel.store)

    }, [viewModel.store])

    return (
        <>
            <Head title="storePageTitle" />

            <UIContent type="form">

                <UIPageTitle title="storePageTitle" description="storePageDescription" rightContent={getPageTitleRightContent()} />

                <UICard isLoading={viewModel.isLoading} loadingType="form">
                    <Form id="form" onSubmit={form.handleSubmit(viewModel.saveStore)}>
                        <UIRow>
                            <UICol lg="12">
                                <LeanInput id="name" label={translate("name")} register={form.register} error={formErrors.name} />
                            </UICol>
                        </UIRow>

                        <UIRow>
                            <UICol lg="12">
                                <LeanInput id="url" label={translate("url")} register={form.register} error={formErrors.url} />
                            </UICol>
                        </UIRow>

                        {viewModel.store === null && (
                            <>
                                <UIRow>
                                    <UICol lg="12">
                                        <LeanInput id="key" label={translate("appKey")} register={form.register} error={formErrors.key} />
                                    </UICol>
                                </UIRow>


                                <UIRow>
                                    <UICol lg="12">
                                        <LeanInput id="token" label={translate("appToken")} register={form.register} error={formErrors.token} />
                                    </UICol>
                                </UIRow>
                            </>
                        )}

                    </Form>
                </UICard>

            </UIContent>
        </>
    )
}

export default StoreFormView
