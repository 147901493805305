import React from "react";
import UIIcon from "../../components/icon/UIIcon";

const Toggle = ({ className, click, icon }) =>
{
    return (
        <a
            href="#toggle"
            className={className ? className : ""}
            onClick={(ev) =>
            {
                ev.preventDefault();
                click(ev);
            }}
        >
            <UIIcon name={icon} />
        </a>
    );
};
export default Toggle;
