import APIErrorHandler from "utils/APIErrorHandler";
import HttpClient from "modules/networking/http-client/HttpClient";

export default function AuthAPI()
{
    // MARK: - Methods

    const login = async (parameters) =>
    {
        try { return await HttpClient.post("/v1/auth/login", parameters) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const requestPasswordReset = async (parameters) =>
    {
        try { return await HttpClient.post("/v1/auth/request-password-reset", parameters) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    const resetPassword = async (parameters) =>
    {
        try { return await HttpClient.post("/v1/auth/reset-password", parameters) }
        catch (error) { return Promise.reject(APIErrorHandler.handle(error)) }
    }

    return {
        login,
        requestPasswordReset,
        resetPassword
    }
}