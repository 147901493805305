import { createContext, useEffect, useState } from "react";
import { languages } from "utils/Utils";

import { IntlProvider } from "react-intl";
import { messages } from "locales/messages";
import { useCookies } from "react-cookie";

export const LanguageContext = createContext({});

const LanguageProvider = ({ children }) => {
  const [cookie, setCookie] = useCookies(["lwApp-language"]);
  const [selectedLanguage, setSelectedLanguage] = useState(cookie["lwApp-language"] || languages.pt);

  const handleLanguageChange = (language) => {
    setCookie("lwApp-language", languages[language]);
    setSelectedLanguage(languages[language]);
  };

  useEffect(() => {
    const browserLanguage = window.navigator.language;

    if (!cookie["lwApp-language"]) {
      if (browserLanguage && browserLanguage.slice(0, 2) !== "pt") {
        if (languages[browserLanguage.slice(0, 2)]) {
          setSelectedLanguage(languages[browserLanguage.slice(0, 2)]);
        }
      }
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ selectedLanguage, handleLanguageChange }}>
      <IntlProvider
        locale={selectedLanguage.urlLang}
        defaultLocale={languages.pt.urlLang}
        messages={messages[selectedLanguage.code]}
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export { LanguageProvider };
