
import { UIContent, UIPageTitle } from "components";
import Head from "../../../layout/head/Head";

const HomeView = () =>
{
    return (
        <>
            <Head title="dashboard" />

            <UIContent>
                <UIPageTitle title="dashboard" />
            </UIContent>
        </>
    );
};

export default HomeView
