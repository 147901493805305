import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { ptForm } from "yup-locale-pt"
import { yupResolver } from "@hookform/resolvers/yup"

Yup.setLocale(ptForm)

const getDefaultValues = () =>
{
    return {
        isActive: true,
    }
}

const useFormWithValidation = (admin) =>
{
    let schema

    if (admin)
    {
        schema = Yup.object().shape({
            email: Yup.string().email().required(),
            isActive: Yup.boolean(),
            name: Yup.string().required()
        })
    }
    else
    {
        schema = Yup.object().shape({
            email: Yup.string().email().required(),
            isActive: Yup.boolean(),
            name: Yup.string().required(),
            password: Yup.string().required()
        })
    }

    return useForm({
        defaultValues: getDefaultValues(),
        resolver: yupResolver(schema)
    })
}

export { useFormWithValidation }
