import { LeanAlert } from "lean-components/LeanComponents"
import { useState } from "react"

import AuthService from "../../../modules/auth/AuthService"

export default function PasswordRecoveryViewModel()
{
    // MARK: - Properties

    const [isLoading, setIsLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const authService = AuthService()


    // MARK: - Methods

    const getPassworResetParameters = (formData) =>
    {
        return {
            email: formData.email
        }
    }

    const send = async (formData) =>
    {
        try
        {
            setIsLoading(true)
            await authService.requestPasswordReset(getPassworResetParameters(formData))
            setIsLoading(false)

            LeanAlert.success({
                title: "Solicitação enviada com sucesso!",
                text: "Enviamos as instruções no seu e-mail para que você possa redefinir sua senha."
            })
        }
        catch (response)
        {
            setIsLoading(false)
            setErrorMessage(response.error.data.message)
        }
    }

    // MARK: - Public

    return {
        errorMessage,
        isLoading,
        send,
    }
}
