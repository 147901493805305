import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { RedirectAs404 } from "./utils/Utils";
import { AuthProvider } from "contexts/AuthContext";
import { LanguageProvider } from "contexts/LanguageContext";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./route/PrivateRoute";
import Layout from "./layout/Index";
import LoginView from "./pages/auth/login/LoginView";
import PasswordRecoveryView from "pages/auth/password-recovery/PasswordRecoveryView";
import PasswordResetView from "pages/auth/password-reset/PasswordResetView";
import LeanPaths from "lean-util/LeanPaths";

import "./assets/scss/dashlite.scss";
import "./assets/scss/style-email.scss";
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const App = (props) =>
{
    return (
        <>
            <LanguageProvider>
                <AuthProvider>
                    <Switch>
                        {/* Auth Pages */}
                        <Route exact path={process.env.PUBLIC_URL + LeanPaths.auth.login} component={LoginView}></Route>
                        <Route exact path={process.env.PUBLIC_URL + LeanPaths.auth.passwordRecovery} component={PasswordRecoveryView}></Route>
                        <Route exact path={process.env.PUBLIC_URL + LeanPaths.auth.passwordReset} component={PasswordResetView}></Route>

                        {/*Main Routes*/}
                        <PrivateRoute exact path="" component={Layout}></PrivateRoute>
                        <Route component={RedirectAs404}></Route>
                    </Switch>
                </AuthProvider>
            </LanguageProvider>

            <ToastContainer closeOnClick draggable pauseOnHover pauseOnFocusLoss rtl={false} newestOnTop theme="light" autoClose={3000} className="lean-toast" position="bottom-right" hideProgressBar={false} />
        </>
    );
};

export default withRouter(App);
