const UIDataTableCellActive = ({ id, isActive, isServerUpdate = true, onUpdate }) =>
{
    // MARK: - Actions

    const onChange = (event) =>
    {
        onUpdate(id, event.target.checked)
    }


    // MARK: - Render

    if (isServerUpdate)
    {
        return (
            <div className="custom-control custom-switch">
                <input type="checkbox" id={id} className="custom-control-input form-control" defaultChecked={isActive} onChange={onChange} />
                <label className="custom-control-label" htmlFor={id}></label>
            </div>
        )
    }
    else
    {
        return (
            <div className="custom-control custom-switch">
                <input type="checkbox" id={id} className="custom-control-input form-control" checked={isActive} onChange={onChange} />
                <label className="custom-control-label" htmlFor={id}></label>
            </div>
        )
    }
};

export default UIDataTableCellActive
