import LeanRoutes from "./LeanRoutes";

const menu = [
    {
        heading: "panels",
    },
    {
        icon: "home",
        text: "dashboard",
        link: LeanRoutes.home,
    },

    {
        heading: "analytics",
    },
    {
        icon: "cart",
        text: "stores",
        link: LeanRoutes.storeList,
    },
    {
        icon: "reload",
        text: "syncs",
        link: LeanRoutes.syncList,
    },
    {
        icon: "chart-up",
        text: "reports",
        link: LeanRoutes.storeReport,
    },
    {
        heading: "accessControl",
    },
    {
        icon: "users",
        text: "users",
        active: false,
        subMenu: [
            {
                text: "administrators",
                link: LeanRoutes.adminList,
            }
        ]
    }
];

export default menu;
