import LeanRoutes from "lean-util/LeanRoutes"
import { useHistory } from "react-router"
import { handleApiError } from "lean-util/LeanHelpers"
import { useEffect, useState } from "react"
import { useStoreService } from "modules/store/useStoreService"
import { useColumns } from "./StoreListViewData"

export function useViewModel()
{
    // MARK: - Properties

    const [storesData, setStoresData] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [paging, setPaging] = useState({ pageIndex: 0, pageSize: 10 })

    const storeService = useStoreService()
    const history = useHistory()


    // MARK: - Methods

    const deleteStore = async (id) =>
    {
        try
        {
            setIsLoading(true)

            await storeService.deleteStore({ id: id })

            setStoresData((await storeService.fetchStoreList(paging)).data)
            setIsLoading(false)
        }
        catch (error)
        {
            setIsLoading(false)
            handleApiError(error)
        }
    }

    const redirectToForm = () =>
    {
        history.push(LeanRoutes.storeAdd)
    }

    const redirectToFormWithId = (id) =>
    {
        history.push(LeanRoutes.storeEdit(id))
    }

    useEffect(() =>
    {
        const fetchStores = async () =>
        {
            try
            {
                setIsLoading(true)
                setStoresData((await storeService.fetchStoreList(paging)).data)
                setIsLoading(false)
            }
            catch (error)
            {
                setIsLoading(false)
                handleApiError(error)
            }
        }

        fetchStores()

    }, [paging])

    return {
        storeColumns: useColumns(redirectToFormWithId, deleteStore),
        storesData,
        isLoading,
        paging,
        redirectToForm,
        setPaging,
    }
}