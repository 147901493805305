import { useFormOptionsAPI } from "./useFormOptionsAPI"

export function useFormOptionsService()
{
    // MARK: - Properties

    const formOptionsAPI = useFormOptionsAPI()


    // MARK: - Methods

    const fetchStores = async (parameters) =>
    {
        return await formOptionsAPI.fetchStores(parameters)
    }

    const fetchYears = async (parameters) =>
    {
        return await formOptionsAPI.fetchYears(parameters)
    }

    return {
        fetchStores,
        fetchYears
    }
}
