import { handleApiError, isNilOrEmpty } from "lean-util/LeanHelpers"
import LeanRoutes from "lean-util/LeanRoutes"
import { useStoreService } from "modules/store/useStoreService"
import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router"
import { useFormWithValidation } from "./StoreFormViewData"

export function useViewModel()
{
    const [isSaving, setIsSaving] = useState()
    const [isLoading, setIsLoading] = useState()
    const [store, setStore] = useState(null)

    const form = useFormWithValidation()
    const history = useHistory()
    const storeService = useStoreService()
    const { id } = useParams()

    const goBack = () =>
    {
        history.push(LeanRoutes.storeList)
    }

    const fetchStore = async () =>
    {
        if (isNilOrEmpty(id))
            return

        try
        {
            setIsLoading(true)
            setStore((await storeService.fetchStore({ id })).data)
            setIsLoading(false)
        }
        catch (error)
        {
            setIsLoading(false)
            handleApiError(error)
        }
    }

    const saveStore = async (formData) =>
    {
        try
        {
            setIsSaving(true)

            if (id)
            {
                await storeService.updateStore(formData)
            }
            else
            {
                await storeService.insertStore(formData)
            }

            setIsSaving(false)
            goBack()
        }
        catch (error)
        {
            handleApiError(error)
            setIsSaving(false)
        }
    }

    useEffect(() =>
    {
        fetchStore()

    }, [id])

    return {
        form,
        isSaving,
        isLoading,
        store,
        goBack,
        saveStore
    }
}
