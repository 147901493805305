import React, { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";

import ptBR from "date-fns/locale/pt-BR";
import { dateFormat } from "utils/Utils";
import { UIIcon } from "../../components";

const CustomInput = forwardRef(({ error, value, onClick, onChange, onBlur, dropdownSelected }, ref) =>
{
    const [inputValue, setInputValue] = useState(value);

    const handleCustomInputChange = (e) =>
    {
        setInputValue(e.target.value);
        onChange(e);
    };

    const handleCustomInputBlur = (e) =>
    {
        setInputValue(value);
        onBlur(e);
    };

    useEffect(() =>
    {
        if (dropdownSelected) setInputValue(dropdownSelected);
    }, [dropdownSelected]);

    return (
        <div onClick={onClick} ref={ref}>
            <div className="form-icon form-icon-left">
                <UIIcon name="calendar"></UIIcon>
            </div>
            <InputMask type="text" mask="99/99/9999" maskChar=" " value={inputValue} onBlur={handleCustomInputBlur} onChange={handleCustomInputChange} className="form-control date-picker" />
            {error && <span className="invalid">{error.message}</span>}
        </div>
    );
});

const LeanDatePicker = ({ error, ...props }) =>
{
    const [dropdownSelected, setDropdownSelected] = useState("");

    const handleChange = (dateValue, event) =>
    {
        if (event.type === "click")
            setDropdownSelected(dateFormat.format(new Date(dateValue)))

        if (props.onChange && typeof props.onChange === "function")
            props.onChange(dateValue, event)
    }

    // MARK: - Render

    return (
        <>
            <DatePicker
                {...props}
                locale={ptBR}
                dateFormat="P"
                onChange={handleChange}
                className="form-control date-picker"
                customInput={<CustomInput dropdownSelected={dropdownSelected} error={error} />}
            />


        </>
    );
};

export default LeanDatePicker;
