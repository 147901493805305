import React, { useContext } from "react";
import { UIAuthContainer, UIButton, UIIcon, UITranslator } from "components";
import { LeanCard, LeanFlagSelect, LeanInput } from "../../../lean-components/LeanComponents";
import { Link } from "react-router-dom";
import { Form, FormGroup, Alert } from "reactstrap";
import { useFormWithValidation } from "./PasswordRecoveryViewData";
import { ImgLeanLogo, ImgLeanLogoLight } from "lean-util/LeanImages";
import Head from "../../../layout/head/Head";
import AuthFooter from "../AuthFooter";
import PasswordRecoveryViewModel from "./PasswordRecoveryViewModel";
import LeanPaths from "lean-util/LeanPaths";
import { useTranslator } from "lean-util/LeanTranslator";

const PasswordRecoveryView = () =>
{
    // MARK: - Properties

    const { translate } = useTranslator()
    const viewModel = PasswordRecoveryViewModel();

    const { formState, register, handleSubmit } = useFormWithValidation();
    const { errors } = formState;


    // MARK: - Actions

    const onSubmit = (formData) =>
    {
        viewModel.send(formData)
    }


    // MARK: - Render

    return (
        <>
            <Head title="recoverPassword" />
            <LeanFlagSelect />

            <UIAuthContainer>
                <div className="nk-block-middle nk-auth-body  wide-xs">
                    <div className="brand-logo pb-4 text-center">
                        <Link to={LeanPaths.auth.login} className="logo-link">
                            <img className="logo-light logo-img logo-img-lg" src={ImgLeanLogoLight} alt="logo" />
                            <img className="logo-dark logo-img logo-img-lg" src={ImgLeanLogo} alt="logo-dark" />
                        </Link>
                    </div>

                    <LeanCard bodyClassName="card-inner-lg">
                        <h4>
                            <UITranslator id="recoverPassword" />
                        </h4>
                        <p className="text-soft">
                            <UITranslator id="weWillSendYouAnEmailWithInstructions" />
                        </p>

                        {viewModel.errorMessage && (
                            <div className="mb-3">
                                <Alert color="danger" className="alert-icon">
                                    <UIIcon name="alert-circle" /> {viewModel.errorMessage}
                                </Alert>
                            </div>
                        )}

                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <FormGroup>
                                <LeanInput
                                    type="text"
                                    id="email"
                                    placeholder={translate("logInEmailPlaceholder")}
                                    className="form-control-lg"
                                    label="E-mail*"
                                    register={register}
                                    error={errors.email}
                                />
                            </FormGroup>
                            <FormGroup>
                                <UIButton type="submit" className="btn-block" color="primary" size="lg" isLoading={viewModel.isLoading}>
                                    <UITranslator id="btnSend" />
                                </UIButton>
                            </FormGroup>
                        </Form>

                        <div className="form-note-s2 text-center pt-4">
                            <Link to={LeanPaths.auth.login}>
                                <strong>
                                    <UITranslator id="backToLoginPage" />
                                </strong>
                            </Link>
                        </div>
                    </LeanCard>
                </div>
                <AuthFooter />
            </UIAuthContainer>
        </>
    );
};

export default PasswordRecoveryView;
